import { HttpClient } from '@angular/common/http';
import { HeaderService } from '../header.options';
import { environment } from 'src/environments/environment';
import { SolicitudReqModel } from '../../../models/credito/api.creditos/solicitudReq.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { ValidacionErrors } from '../../utileria/validacion.error';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewMsg} from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';

@Injectable({ providedIn: 'root' })
export class ApiCreditosService {

    constructor(
        private httpClient: HttpClient,
        private header: HeaderService,
        private dialog: MatDialog,
        private validacionError: ValidacionErrors

    ) {
    }

    getCapacidadDePago(numeroEmpleado: string, idSolicitud: string, idProducto: number): Observable<any> {
        const parametros = new URLSearchParams();
        parametros.append('numeroEmpleado', numeroEmpleado);
        parametros.append('idSolicitud', '' + idSolicitud);
        parametros.append('idProducto', '' + idProducto);
        return this.httpClient.get(environment.api_url_creditos + parametros.toString(),
            { headers: this.header.getHeaderOption(false) }).
            pipe(
                catchError(e => {
                throw e;
                })

            );
    }

    getCreditosSocio(numeroEmpleado: string): Observable<any> {
        const parametros = new URLSearchParams();
        parametros.append('numeroEmpleado', numeroEmpleado);
        parametros.append('idProducto', '1');
        return this.httpClient.get(environment.api_url_credito + parametros.toString(),
            { headers: this.header.getHeaderOption(false) }).
            pipe(
                catchError(e => {
                    throw e;
                })
            );
    }


    postSolicitud(solicitudReq: SolicitudReqModel): Observable<any> {

        return this.httpClient.post(environment.api_url_solicitudes, solicitudReq,
            { headers: this.header.postHeaderOption(false) }).
            pipe(
                catchError(e => {
                    if (e.status >= 400 && e.status < 500) {
                        this.dialog.closeAll();
                        this.openDialogMsg(this.validacionError.getError(e));
                    }
                throw e;
                })
            );
    }

    openDialogMsg(msg: string): void {
        this.dialog.open(DialogOverviewMsg, {
          width: '500px',
          data: {
            message: msg,
            disableClose: true,
            autoFocus: false
          }
        });
      }
}
