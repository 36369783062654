import { Injectable, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { UserService } from '../user.service';
import { UserModel } from '../../models';
import { TokenApiService } from '../token.service';
import { DialogOverviewMsg } from '../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { JwtService } from '../jwt.service';
import { environment } from '../../../../environments/environment';
import { HomeService } from '../home.service';

@Injectable()
export class AuthGuardAccessTokenService implements CanActivate {

  constructor(private router: Router,
    private userService: UserService,
    private tokenService: TokenApiService,
    private jwtService: JwtService,
    private homeService: HomeService,
    private dialog: MatDialog) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const uri = window.location.href;
    let bandera = false;

    if (uri.indexOf('guatemala') !== -1) {
      sessionStorage.setItem('pais', '2')
    }


    if ((this.userService.recoverToken() && this.tokenService.validarToken()) || bandera == false) {
      return true;
    }
    this.jwtService.destroyData();
    this.openDialogMsg('Inicia sesión para continuar', true);
    return false;
  } 

  openDialogMsg(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route) {
        this.router.navigateByUrl('/');
      }
    });
  }
}
