import { Component, ViewChild, ElementRef, Renderer2, OnInit } from '@angular/core';
import { Producto } from '../../../core/services/home/home-auth-resolver.service';
import { UserService } from '../../../core/services/user.service';
import { HomeService } from '../../../core/services/home.service';

@Component({
    selector: 'app-aside',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss']
})
export class AsideComponent implements OnInit {

    imagenDefault: string;
    listaProgreso: string[];
    urlImagen: string;
    urlImagenPerfil: string;
    areaEmpleado: string;
    numEmpleado: string;
    nombreEmpleado: string;
    correoEmpleado: string;
    puesto: string;
    telefono: string;
    direccion: string;
    onceIntent: boolean;
    antiguedad: string;

    @ViewChild('bkgid', { static: true }) bkgid: ElementRef;
    @ViewChild('aside1', { static: true }) aside1: ElementRef;
    @ViewChild('aside2', { static: true }) aside2: ElementRef;

    constructor(
        private renderer: Renderer2,
        private userService: UserService,
        private homeService: HomeService) {
    }

    ngOnInit() { 

        this.homeService.openPerfil.subscribe(actualizaPerfil => {
            console.log(actualizaPerfil)
            if (actualizaPerfil) {
                this.direccion = sessionStorage.getItem('direccionEmpleado');
            }

          });
    }

    recuperarInformacion(): void {
        if (this.userService.recoverToken()) {
            this.nombreEmpleado = sessionStorage.getItem('nombreCompleto');
            this.numEmpleado = this.userService.getNumeroEmpleado();
            this.puesto = sessionStorage.getItem('puestoEmpleado');
            this.areaEmpleado = sessionStorage.getItem('areaEmpleado');
            this.correoEmpleado = sessionStorage.getItem('correoEmpleado');
            this.obtenerImagenEmpleado();
            this.telefono = sessionStorage.getItem('telefonoEmpleado');
            this.direccion = sessionStorage.getItem('direccionEmpleado');
            if (sessionStorage.getItem('antiguedad')) {
                const fechaIngreso = sessionStorage.getItem('antiguedad').split('/')[2];
                const anios = this.getAnioActual() - parseInt(fechaIngreso, 10);
                if (anios === 1) {
                    this.antiguedad = anios + ' Año';
                } else {
                    this.antiguedad = anios + ' Años';
                }
            }
        }
    }

    getAnioActual(): number {
        return new Date().getFullYear();
    }

    obtenerImagenEmpleado(): void {
        this.urlImagenPerfil = 'https://portal.socio.gs/foto/' + this.areaEmpleado + '/empleados/' + this.numEmpleado + '.jpg';
        this.onceIntent = true;
    }

    cargarImagenPerfilPorDefecto() {
        if (this.onceIntent && this.areaEmpleado !== 'elektra') {
            this.urlImagenPerfil = 'https://portal.socio.gs/foto/elektra/empleados/' + this.numEmpleado + '.jpg';
            this.onceIntent = false;
        } else {
            this.urlImagenPerfil = '../../../../assets/img/imagenPerfil.png';
        }
    }

    cambiarImagenAside(imagen): void {
        if (imagen !== '') {
            this.imagenDefault = imagen;
            this.renderer.setStyle(this.aside1.nativeElement, 'display', 'block');
            this.renderer.setStyle(this.aside2.nativeElement, 'display', 'none');
        } else {
            this.recuperarInformacion();
            this.renderer.setStyle(this.aside1.nativeElement, 'display', 'none');
            this.renderer.setStyle(this.aside2.nativeElement, 'display', 'block');
        }
    }

    setBkgStyles(producto_id): void {
        switch (producto_id) {
            case Producto.HOME:
                break;
            case Producto.ADELANTO_EFECTIVO:
                this.renderer.addClass(this.bkgid.nativeElement, 'bkg_ade');
                this.renderer.removeClass(this.bkgid.nativeElement, 'bkg_ps');
                break;
            case Producto.PRESTAMO:
                this.renderer.addClass(this.bkgid.nativeElement, 'bkg_ps');
                this.renderer.removeClass(this.bkgid.nativeElement, 'bkg_ade');
                break;
            case Producto.PERFIL_SOCIO:
                this.renderer.setStyle(this.aside1.nativeElement, 'display', 'none');
                this.renderer.setStyle(this.aside2.nativeElement, 'display', 'block');
                break;
            default:
                this.renderer.addClass(this.bkgid.nativeElement, 'bkg_ade');
                this.renderer.removeClass(this.bkgid.nativeElement, 'bkg_ade');
                break;
        }
    }

    setProgress(produto_id) {
        switch (produto_id) {
            case Producto.ADELANTO_EFECTIVO:
            case Producto.PRESTAMO:
                this.listaProgreso = ['datossocio', 'altadocumentos', 'referenciasfamiliares', 'configurador', 'autorizasolicitud'];
                break;
            case Producto.HOME:
                break;
        }
    }
}
