import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PerfilsocioComponent } from './perfilsocio.component';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'perfilsocio',
    component: PerfilsocioComponent,
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class PerfilsocioModule {}