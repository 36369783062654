  <form #formRF id="formRF" class="content_form" [formGroup]="formReferencias" (ngSubmit)="validarFormulario(formReferencias.valid) && formReferencias.valid && guardarReferencias()" autocomplete="off">
    <h2 *ngIf="showLabels" class="title">3. Referencias Familiares.</h2>

    <p>Ingresa los siguientes datos.</p>
    <p>Referencias localizables y que sean mayores de edad.</p>
    <br>

    <div *ngFor="let referencia of referenciaModel; let i = index;">

        <h3 class="subtitle">Referencia {{i+1}}:</h3><br>

        <div style="display: none" formArrayName="idReferencias">
            <input type="hidden" [formControlName]="i" name="txtIdRefencia{{i+1}}">
        </div>

        <div class="row">
            <div class="col" formArrayName="nombres">
                <mat-form-field class="example-full-width">
                    <input type="text" [formControlName]="i" name="txtNombre{{i+1}}" placeholder="Nombre (s)" matInput
                        maxlength="30">
                    <mat-error *ngIf="!formReferencias.controls.nombres.controls[i].errors?.required">
                        Verifica el campo
                    </mat-error>
                </mat-form-field>
            </div>

        </div>

        <div class="row">
            <div class="col col-6" formArrayName="apellidosPaternos">
                <mat-form-field class="example-full-width">
                    <input [formControlName]="i" type="text" name="txtApellidoPaterno{{i+1}}" matInput maxlength="30"
                        placeholder="Apellido Paterno">
                    <mat-error *ngIf="!formReferencias.controls.apellidosPaternos.controls[i].errors?.required">
                        Verifica el campo
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col col-6" formArrayName="apellidosMaternos">
                <mat-form-field class="example-full-width">
                    <input [formControlName]="i" type="text" name="txtApellidoMaterno{{i+1}}" matInput maxlength="30"
                        placeholder="Apellido Materno">
                    <mat-error *ngIf="!formReferencias.controls.apellidosMaternos.controls[i].errors?.required">
                        Verifica el campo
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div style="display: none" formArrayName="idTelefonosCasa">
                <input type="hidden" [formControlName]="i" name="txtIdTelefonoCasa{{i+1}}">
            </div>
            <div class="col col-6" formArrayName="telefonosCasa">
                <mat-form-field class="example-full-width">
                    <input [formControlName]="i" type="tel" mask="00 00 00 00 00" name="txtTelefonoCasa{{i+1}}" matInput maxlength="14"
                        placeholder="Teléfono Casa (10 dígitos)" (blur)="validaTelCompleto()">
                    <mat-error *ngIf="formReferencias.controls['telefonosCasa'].controls[i].errors
                        && !formReferencias.controls.telefonosCasa.controls[i].errors?.telConsecutivo">
                        Verifica el número de teléfono
                    </mat-error>
                    <mat-error
                        *ngIf="!!formReferencias.controls.telefonosCasa.controls[i].errors?.telConsecutivo">
                        Verifica que el número de teléfono sea personal.
                    </mat-error>
                </mat-form-field>
            </div>
            <div style="display: none" formArrayName="idTelefonosCelular">
                <input type="hidden" [formControlName]="i" name="txtIdTelefonoCelular{{i+1}}">
            </div>
            <div class="col col-6" formArrayName="telefonosCelular">
                <mat-form-field class="example-full-width">
                    <input [formControlName]="i" type="tel" mask="00 00 00 00 00" name="txtTelefonoCelular{{i+1}}" matInput maxlength="14"
                        placeholder="Teléfono Móvil (10 dígitos)" (blur)="validaTelCompleto()">
                    <mat-error *ngIf="formReferencias.controls['telefonosCelular'].controls[i].errors
                        && !formReferencias.controls.telefonosCelular.controls[i].errors?.telConsecutivo">
                        Verifica el número de teléfono
                    </mat-error>
                    <mat-error *ngIf="!!formReferencias.controls.telefonosCelular.controls[i].errors?.telConsecutivo">
                        Verifica que el número de teléfono sea personal.
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col col-6">
                <mat-form-field class="example-full-width" formArrayName="correos">
                    <input [formControlName]="i" type="email" name="txtCorreo{{i+1}}" matInput placeholder="Correo electrónico (opcional)" maxlength="50" (blur)="verificarEmail()" email>
                    <mat-error *ngIf="formReferencias.controls['correos'].controls[i].errors
                    && !formReferencias.controls.correos.controls[i].errors?.prohibidos">
                        Verifica el correo electrónico
                    </mat-error>
                    <mat-error *ngIf="!!formReferencias.controls.correos.controls[i].errors?.prohibidos">
                        Verifica que el correo electrónico sea personal.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col col-6" formArrayName="parentescos">
                <mat-form-field>
                    <mat-select [formControlName]="i" name="selParentesco" placeholder="Parentesco">
                        <mat-option *ngFor="let parentesco of lstParentescos; let j = index;" [value]="j">
                            {{ parentesco }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="formReferencias.controls['parentescos'].controls[i].value === 4">
            <div class="col col-6" formArrayName="otros">                
            </div>
            <div class="col col-6" formArrayName="otros">
                <mat-form-field class="example-full-width">
                    <input [formControlName]="i" type="text" name="txtOtro{{i+1}}" matInput maxlength="30" placeholder="Especificar:">
                    <mat-error>
                        Verifica el campo
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <br>
    </div>

    <div *ngIf="showLabels" #btnRF id="btnRF" class="col pad btns_foot">
        <button type="button" class="btn atras" (click)="routeFunction('/altadocumentos')"  name="btnAtras"  routerLinkActive="router-link-active">Atrás</button>
        <button type="button" class="btn cancelar" name="btnCancelar" (click)="openDialogCancelacionesMensaje()" routerLinkActive="router-link-active">Cancelar</button>
        <button class="btn all" name="btnContinuar" type="submit">Continuar</button>
    </div>
    <div *ngIf="showEditar && !showLabels" #btnPS id="btnPS" class="col pad btns_foot">
        <button type="button" class="btn atrasps" (click)="routeFunction('/home')"  name="btnAtras"  routerLinkActive="router-link-active">Atrás</button>
        <button type="button" class="btn all" (click)="editar()" name="btnEditar">Editar</button>
    </div>
    <div *ngIf="!showEditar && !showLabels" #btnGuardar id="btnGuardar" class="col pad btns_foot">
        <button type="button" class="btn cancelar" (click)="cancelar()">Cancelar</button>
        <button type="button" class="btn all" (click)="saveData(formReferencias.valid)">Guardar</button>
    </div>
</form>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
    
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  

    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  

    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  

        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  

    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>  