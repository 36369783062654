import { HttpClient } from '@angular/common/http';
import { HeaderService } from '../header.options';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogOverviewMsg} from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';

@Injectable({ providedIn: 'root' })
export class ApiValidacionesService {
    constructor(
        private httpClient: HttpClient,
        private header: HeaderService,
        private dialog: MatDialog,
    ) {
    }
    // Validaciones de buro y aviso de privacidad
    getValidaciones(idValidacion: number, numeroEmpleado: string): Observable<any> {
        const parametros = new URLSearchParams();
        parametros.append('numeroEmpleado', numeroEmpleado);
        parametros.append('idValidacion', '' + idValidacion);
        return this.httpClient.get(environment.api_url_bfs_validaciones + parametros.toString(),
            {
                headers: this.header.getHeaderOption(false)
            }).pipe(
                catchError( e => {
                throw e;
                })
              );
    }

    getValidacionesHome(numeroEmpleado: string, idValidacion: any, producto: number): Observable<any> {
        const parametros = new URLSearchParams();
        parametros.append('numeroEmpleado', numeroEmpleado);
        parametros.append('idValidacion', '' + idValidacion);
        const validacionProducto = producto === 1 ? environment.api_url_bfs_validaciones : environment.api_url_ps_validaciones;
        return this.httpClient.get(validacionProducto + parametros.toString(),
        {
            headers: this.header.getHeaderOption(false)
        }).pipe(
            catchError( e => {
                throw e;
            })
        );
    }

    openDialogMsg(msg: string): void {
        this.dialog.open(DialogOverviewMsg, {
          width: '500px',
          data: {
            message: msg,
            disableClose: true,
            autoFocus: false
          }
        });
      }
}
