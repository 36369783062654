import {
  Component,
  OnInit,
  ViewChild,
  Renderer2,
  ElementRef
} from '@angular/core';
import { UserService } from 'src/app/core';
import { Router } from '@angular/router';
import { NotificacionModel } from '../../../core/models/credito/notificaciones/notificacion.model';
import { NotificacionService } from 'src/app/core/services/credito/api.notificaciones/api.notificaciones.service';
import { NotificacionEliminadaModel } from '../../../core/models/credito/notificaciones/notificacionEliminada.model';
import { EstatusNotificaciones } from 'src/app/core/services/utileria/utileria.enum';
import { environment } from '../../../../environments/environment';
import { DialogCancelacionComponent } from '../../components/modales/modalMaterialCancelaciones/dialog-overview-msg1';
import { MatDialog } from '@angular/material/dialog';
import { DialogMensajeComponent } from '../../components/modales/modalMaterialCancelaciones.1/dialog-overview-msg1';
import { DialogOverviewMsg } from '../../components/modales/modalMaterialMsg/dialog-overview-msg';


import { ApiAdelantoService } from '../../../core/services/credito/api.adelanto/api.adelanto';
import { UserModel } from '../../../core/models';
import { TokenApiService } from 'src/app/core/services/token.service';
import { HomeService } from 'src/app/core/services/home.service';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  name = 'Header';
  sesionActiva: boolean;
  tituloPrincipal: string;
  uriProducto: string;
  uriSeccion: string;
  modalNotificaciones = false;
  notificaciones: NotificacionModel[] = [];
  nombreEmpleado: string;
  areaEmpleado: string;
  active: boolean;
  showCreditosBC: boolean;
  showProducto: boolean;
  showLogoBaz : boolean;
  numeroEmpleado: string;
  urlImagen: string;
  onceIntent: boolean;
  onceIntentBO: boolean;
  errorConsulta: string;
  rutaDestinoCancelaciones: string;
  guatemala = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private renderer: Renderer2,
    private apiAdelanto: ApiAdelantoService,
    private dialog: MatDialog,
    private tokenService: TokenApiService,
    private homeService: HomeService,
    private cookieServ: CookieService,
    private notificacionService: NotificacionService
  ) { }

  @ViewChild('tituloProducto', { static: true }) titulo: ElementRef;
  @ViewChild('dropdwn') dropdwn: ElementRef;

  ngOnInit() {
    this.showCreditosBC = false;
    this.active = false;
    setTimeout(() => {
      this.validarSesion();
    }, 5000);
    setTimeout(() => {
      this.obtenerNotificaciones();
    }, 10000);
    setInterval(() => {
      this.obtenerNotificaciones();
    }, 2000 * 60);
  }

  validarSesion() {
    if(sessionStorage.getItem('pais')=='2'){
      this.guatemala = true;
    }
    
    if (this.userService.recoverToken()!=undefined && this.userService.getNumeroEmpleado() != null) {

      this.numeroEmpleado = this.userService.getNumeroEmpleado();
      this.nombreEmpleado = sessionStorage.getItem('nombrePila');
      this.areaEmpleado = sessionStorage.getItem('areaEmpleado');

      this.obtenerImagenEmpleado();
  
      this.sesionActiva = true;
    } else {
      if(this.cookieServ.get(environment.cookieNameSAML)){
        console.log("Cookie SI Existe");
        this.ObtenerInformacionUsuarioOktaSAML();
        this.sesionActiva = true;
      }else{
        this.sesionActiva = false;
        console.log("Cookie NO Existe");
      }
    }
    console.log("Sesion Activa: " + this.sesionActiva);
  }

  private ObtenerInformacionUsuarioOktaSAML() {
    this.userService.validarSesionSAML().subscribe(userInfo => {
      console.log(userInfo);
      if(userInfo.user.nameID){
        this.userService.setNumeroEmpleado(userInfo.user.attributes.numeroEmpleado);
        sessionStorage.setItem('nombreCompleto', userInfo.user.attributes.nombrecompleto);
        sessionStorage.setItem('areaEmpleado', userInfo.user.attributes.compañia);
        sessionStorage.setItem('puestoEmpleado', userInfo.user.attributes.puesto);
        sessionStorage.setItem('correoEmpleado', userInfo.user.attributes.correo);
        sessionStorage.setItem('tipoEmpleado', userInfo.user.attributes.tipoEmpleado);
        sessionStorage.setItem('nombrePila', userInfo.user.attributes.nombrecompleto.split(' ')[1]);

        this.numeroEmpleado = this.userService.getNumeroEmpleado();
        this.nombreEmpleado = sessionStorage.getItem('nombrePila');
        this.areaEmpleado = sessionStorage.getItem('areaEmpleado');

        let token = CryptoJS.AES.encrypt(userInfo.user.sessionIndex.trim(), environment.contra_crypto).toString();

        this.userService.obtenerTokenApigee().subscribe(tokenApiGee => {
          this.tokenService.guardarToken(tokenApiGee.access_token);
          this.userService.obtenerTokenApigeeOnpremise().subscribe(tokenApiGeeOnpremise => {
            this.tokenService.guardarTokenOnpremise(tokenApiGeeOnpremise.access_token);
          })
          if (sessionStorage.getItem('producto_id')) {
            if (+sessionStorage.getItem('producto_id') === 1) {
              // Rediccionar ADE
              this.homeService.iniciarADE();
            } else if (+sessionStorage.getItem('producto_id') === 2) {
              // Rediccionar CP
              this.homeService.iniciarCP();
            }
          }
        })
      const data = {
        'token': token
      };
      // Guardamos la información del token
      this.userService.setAuth(new UserModel(data.token));
      this.obtenerImagenEmpleado();
      this.sesionActiva = true;

      }else {
        console.log("NO");
        this.cookieServ.delete(environment.cookieNameSAML);
        this.sesionActiva = false;
      }
    },
    err => {
      console.log('Lo sentimos, no fue posible recuperar la información del usuario: ' + JSON.stringify(err));
    });
    }

  isLogged(): boolean {
    this.validarSesion();
    if (this.sesionActiva) {
      return true;
    }
    return false;
  }

  eventBreadcrumb(rutaDestino: string): void {
    this.rutaDestinoCancelaciones = rutaDestino;
    this.getCancelacion(this.router.url);
  }

  getCancelacion(ruta: string) {
    this.dialog.closeAll();
    switch (ruta) {
      case '/configurador': {
        this.openDialogCancelacionesMensaje(1);
        break;
      }
      case '/autorizafirma': {
        this.openDialogCancelacionesMensaje(1);
        break;
      }
      case '/autorizasolicitud': {
        this.openDialogCancelacionesMensaje(1);
        break;
      }
      case '/datossocio': {
        this.openDialogCancelacionesMensaje(2);
        break;
      }
      case '/altadocumentos': {
        this.openDialogCancelacionesMensaje(2);
        break;
      }
      case '/referenciasfamiliares': {
        this.openDialogCancelacionesMensaje(2);
        break;
      }
      default: {
        sessionStorage.removeItem('idProductoRespuesta');
        sessionStorage.removeItem('producto_solicitud');
        this.routeFunction('/home');
        break;
      }
    }
  }

  openDialogCancelaciones(): void {
    const dialogRef = this.dialog.open(DialogCancelacionComponent, {
      width: '500px',
      data: {
        message: '',
        opciones: true,
        disableClose: true,
        rutaDestino: this.rutaDestinoCancelaciones
      },
      autoFocus: false
    });
  }

  openDialogCancelacionesMensaje(tipoModal: number): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        message: '¿Estás seguro que deseas salir? No se guardará el avance.',
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        switch (tipoModal) {
          case 1: {
            this.openDialogCancelaciones();
            break;
          }
          case 2: {
            this.router.navigate([this.rutaDestinoCancelaciones], { skipLocationChange: false });
            break;
          }
        }
      }
    });
  }

  openDialogCancelacionesMensajeNoti(): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: 'Esta acción ya fue realizada. Por favor verifica tu última notificación',
        disableClose: true
      },
      autoFocus: false
    });
    
  }

  routeFunction(ruta: string): void {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  showCreditosBreadCrumbs(): void {
    this.showCreditosBC = true;
  }

  quitarProducto() {
    this.showProducto = false;
  }

  ponerProducto() {
    this.showProducto = true;
  }

  quitarLogoBAZ() {
    this.showLogoBaz = false;
  }

  ponerLogoBAZ() {
    this.showLogoBaz = true;
  }


  obtenerNotificaciones() {
    if ( this.userService.recoverToken() !== '' && this.userService.recoverToken() !== undefined) {
    this.numeroEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    this.notificacionService.obtenerTipoNotificaciones(1).subscribe(responseTipoNotificaciones =>{
      if(responseTipoNotificaciones.resultado !=null &&  responseTipoNotificaciones.resultado!= '' && responseTipoNotificaciones.resultado.tiposNotificacion != null && responseTipoNotificaciones.resultado.tiposNotificacion != '' ){
        var tiposNotificaciones = responseTipoNotificaciones.resultado.tiposNotificacion;
        sessionStorage.setItem('tiposNotificaciones', JSON.stringify(tiposNotificaciones));
      }

      this.notificacionService
      .obtenerNotificaciones(this.numeroEmpleado, 'desc', '5')
      .subscribe(response => {
        this.notificaciones = response.resultado.notificaciones as NotificacionModel[];
        this.errorConsulta = '';
        this.validarNotificacionesLeidas();
      }, error => {
        if (error.status === 500) {
          this.errorConsulta = 'Ocurrió un error al conectar con los servicios. Intentalo en unos minutos.';
        }
      });
    });

    }
  }

  validaNotificacionesConsulta() {
    return this.notificaciones.length === 0 && (this.errorConsulta === undefined || this.errorConsulta === '');
  }

  validarNotificacionesLeidas() {
    const notificacionesNoLeidas = this.notificaciones.filter(notificacion =>
      notificacion.idEstatus === EstatusNotificaciones.ESTATUS_ACTIVO
      ).length > 0;
    notificacionesNoLeidas ? this.active = true : this.active = false;

  }

  validarNotificaciones() {
    return this.notificaciones.length === 0;

  }

  routeToPerfilSocio() {
    sessionStorage.setItem('producto_id' , '3');
    this.cambiarTitulosProducto('Perfil del Empleado', 'Perfil del Empleado','');
    this.router.navigate(['/perfilsocio'], { skipLocationChange: false });
  }

  cerrarSesion() {
    this.userService.cierreSesion();
        this.userService.cerrarSesionSAML().subscribe(logout => {
      console.log(logout);
      if(logout.message){
        console.log("Sesion terminada");
        this.sesionActiva = false;
        this.router.navigate(['/externalRedirect', { externalUrl: environment.redirectDashboardOkta}], {
                    skipLocationChange: true,
               });
          
      }
     
    });
  }


  mostrarNotificacion() {
    this.modalNotificaciones = true;
  }

  cerrarFoto() {
    this.modalNotificaciones = false;
  }

  cambiarTitulosProductoGral(tituloPrincipal): void {
    this.tituloPrincipal = tituloPrincipal;
  }


  cambiarTitulosProducto(tituloPrincipal, uriProducto, seccion): void {
    this.tituloPrincipal = tituloPrincipal;
    this.uriProducto = uriProducto;
    this.uriSeccion = seccion;
    
  }


  abrirNotificacion(notificacionModificar: NotificacionModel, e) {
    sessionStorage.removeItem('folioCredito');
    sessionStorage.removeItem('producto_solicitud');
    sessionStorage.removeItem('idProductoRespuesta');

    sessionStorage.setItem('mensajesNotificacion',JSON.stringify(notificacionModificar.mensajes));
    if (notificacionModificar.idEstatus === EstatusNotificaciones.ESTATUS_LEIDO) {
      this.obtenerNotificacion(notificacionModificar);
    } else {
      this.numeroEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
      const notificacionEliminar = new NotificacionEliminadaModel();
      notificacionEliminar.numeroEmpleado = this.numeroEmpleado;
      notificacionEliminar.idNotificacion = notificacionModificar.id;
      notificacionEliminar.idEstatus = EstatusNotificaciones.ESTATUS_LEIDO;
      this.notificacionService.putEliminarNotificacion(notificacionEliminar).subscribe(response => {
        this.notificaciones = this.notificaciones.map(notificacion => {
          if (notificacion.id === notificacionModificar.id) {
            notificacion.idEstatus = EstatusNotificaciones.ESTATUS_LEIDO;
          }
          return notificacion;
        });
        this.obtenerNotificacion(notificacionModificar);
      }, error => {
        console.log('Error al abrir notificaciones: ' + JSON.stringify(error));
      });

    }
    e.stopPropagation();
    e.preventDefault();
  }


  obtenerNotificacion(notificacionModificar) {
      this.validarNotificacionesLeidas();
      const existeProducto = notificacionModificar.detalle.indexOf('adelanto-efectivo/v1/');
      if (existeProducto >= 0) {
        const idProducto = notificacionModificar.detalle.substring(existeProducto + 21, notificacionModificar.detalle.length);
        this.obtenerCredito(idProducto, this.obtenerEstatusAdeNotificacion(notificacionModificar.tipo));
       }
  }

  obtenerCredito(idProducto, idEstatusAdE) {
    this.apiAdelanto.getAdelantoPorSolicitud(idProducto).subscribe(adelanto => {
      if (idEstatusAdE === 2 && adelanto.resultado.idEstatus === 3 ){
        this.renderer.removeClass(this.dropdwn.nativeElement, 'show');
        sessionStorage.setItem('folioCredito', adelanto.resultado.idAdelanto);
        this.cerrarFoto();
        this.router
        .navigateByUrl('/#', { skipLocationChange: true })
        .then(() => this.router.navigate(['/resumen']));
      } else if (adelanto.resultado.idEstatus === idEstatusAdE ) {
        this.renderer.removeClass(this.dropdwn.nativeElement, 'show');
        this.guardarInformacion(1, idProducto);
        this.quitarProducto();
        this.cerrarFoto();
        this.router
        .navigateByUrl('/#', { skipLocationChange: true })
        .then(() => this.router.navigate(['/aprobacion']));
      } else {
        this.openDialogCancelacionesMensajeNoti();
      }

    }, error => {
      console.log(error);
    });
  }

  obtenerEstatusAdeNotificacion(tipoNotificacion: number): number {
    let idEstatusAdE: number=0;
    var tipoNotificacionArray = JSON.parse(sessionStorage.getItem('tiposNotificaciones'));
    tipoNotificacionArray.forEach(element => {
      if (element.id === tipoNotificacion){
        idEstatusAdE = element.idEstatusProducto;
      }
      return idEstatusAdE;
    });
  return idEstatusAdE;
  }

  guardarInformacion(productoSolicitud, idProductoRespuesta): void {
    if (window.sessionStorage) {
      sessionStorage.producto_solicitud = productoSolicitud;
      sessionStorage.idProductoRespuesta = idProductoRespuesta;
    }
  }
  // Funcion para ocultar el titulo en aprobacion y resumen.
  ocultarTitulo(tipoEstilo) {
    this.renderer.setStyle(this.titulo.nativeElement, 'display', tipoEstilo);
  }

  eliminarNotificacion(itemborrar, e) {
    this.numeroEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';

    const notificacionEliminar = new NotificacionEliminadaModel();
    notificacionEliminar.numeroEmpleado = this.numeroEmpleado;
    notificacionEliminar.idNotificacion = itemborrar.id;
    notificacionEliminar.idEstatus = EstatusNotificaciones.ESTATUS_ELIMINADO;

    this.notificacionService.putEliminarNotificacion(notificacionEliminar).subscribe(response => {

      this.notificaciones = this.notificaciones.filter(item =>
        item.id !== itemborrar.id);
      if (this.notificaciones.length === 0) {
        this.renderer.removeClass(this.dropdwn.nativeElement, 'show');
        this.cerrarFoto();

      }
      this.validarNotificacionesLeidas();
    }, error => {
      console.log('Error al eliminar notificaciones: ' + JSON.stringify(error));
    });
    e.stopPropagation();
    e.preventDefault();
  }

  onManejoSesion() {
    this.userService.cierreSesion();
    this.userService.iniciarSesion();
  }

  obtenerImagenEmpleado() {
    this.urlImagen = 'https://portal.socio.gs/foto/' + this.areaEmpleado + '/empleados/' + this.numeroEmpleado + '.jpg';
    this.onceIntent = true;
    this.onceIntentBO = true;

  }

  cargarImagenPorDefecto(e) {
    if (this.onceIntent && this.areaEmpleado !== 'elektra') {
      this.urlImagen = 'https://portal.socio.gs/foto/elektra/empleados/' + this.numeroEmpleado + '.jpg';
      this.onceIntent = false;
    } else if (this.onceIntentBO ) {
      this.urlImagen = 'https://portal.socio.gs/foto/back_office/empleados/' + this.numeroEmpleado + '.jpg';
      this.onceIntentBO = false;
    } else {
      this.urlImagen = '../../../../assets/img/person-icon.jpg';
    }
  }
}
