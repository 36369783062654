import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-xss',
  template: ``,
  styles: ['html, body { display: none; }']
})
export class XXSComponent implements OnInit {

  constructor() {}

  ngOnInit() {
    if (self === top) {
      document.documentElement.style.display = 'block';
      document.body.style.display = 'block';
    } else {
      this.redireccionAlSitio();
    }
  }

  redireccionAlSitio() {
      try {
          const surl = self;
          if (this.validateURL('' + surl)) {
            top.location.href = '' + surl;
          } else {
            throw new this.invalidURLException();
          }
      } catch (e) {
          if (e instanceof this.invalidURLException) {
              console.log(e);
          }
      }
  }

  invalidURLException() {
    const message = 'Se intentó abrir una página web de dominio extranjero. No permitido.';
    this.toString = function () {
      return message;
    };
  }

  validateURL(surl: string) {
    const url = this.parseURL(surl);
    const urlHostname = url.hostname.trim();

    if (urlHostname === '') {
        return true;
    } else {
        return urlHostname.toUpperCase() === location.hostname.trim().toUpperCase();
    }
  }

  parseURL(url: string) {
    const a = document.createElement('a');
    a.href = url;
    return {
        source: url,
        protocol: a.protocol.replace(':', ''),
        hostname: a.hostname,
        host: a.host,
        port: a.port,
        query: a.search,
        params: (function () {
            let ret = {},
                seg = a.search.replace(/^\?/, '').split('&'),
                len = seg.length, i = 0, s;
            for (; i < len; i++) {
                if (!seg[i]) { continue; }
                s = seg[i].split('=');
                ret[s[0]] = s[1];
            }
            return ret;
        })(),
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
        hash: a.hash.replace('#', ''),
        path: a.pathname.replace(/^([^\/])/, '/$1'),
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
        segments: a.pathname.replace(/^\//, '').split('/')
    };
  }
}
