import { Component, OnInit, Host, OnDestroy, AfterContentInit, ViewChild, AfterViewInit } from '@angular/core';
import { Errors } from '../../../../app/core';
import { AppComponent } from '../../../../app/app.component';
import { ApiValidacionesService } from '../../../core/services/credito/api.adelanto.validaciones/api.validaciones.service';
import { UserService } from '../../../core/services/user.service';
import { DialogOverviewMsg } from '../../components/modales/modalMaterialMsg/dialog-overview-msg';
import { MatDialog } from '@angular/material/dialog';
import { DialogValidacionComponent } from '../../components/modales/modalMaterial.1/dialog-overview';
import { HomeService } from '../../../core/services/home.service';
import { TipoProducto, TipoDocumento,EstatusConsentimiento,OpcionesIntentosAltaDocumentos } from '../../../core/services/utileria/utileria.enum';
import { DialogReintentarComponent } from '../../components/modales/modalMaterialReintentar/dialog-overview-msg';
import { ValidacionErrors } from 'src/app/core/services/utileria/validacion.error';
import { ApiDocumentosService } from 'src/app/core/services/credito/api.documentos/api.documentos';
import { ArchivoService } from 'src/app/core/services/archivos.service';
import { DialogOverview } from '../../components/modales/modalMaterial/dialog-overview';
import { ConsentimientoModel } from 'src/app/core/services/credito/api.consentimiento/consentimiento.model';
import { ApiConsentimientoService } from 'src/app/core/services/credito/api.consentimiento/api.consentimiento.service';
import { TokenApiService } from '../../../core/services/token.service';
import { ApiAdelantoService } from '../../../core/services/credito/api.adelanto/api.adelanto';
import { DialogCondicionadoComponent } from '../../components/modales/modalMaterialCondicionado/dialog-overview-msg1';





import { Router } from '@angular/router';
import { DialogRecompraComponent } from '../../components/modales/modalRecompra/dialog-overview-msg1';
import { RequestDocumentoModel } from 'src/app/core/models/credito/api.documentos/req.documentos.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy, AfterContentInit {
  errors: Errors = { error: {} };
  isSubmitting = false;
  txtButton: string;
  showHomeContent: number;
  showResumen: boolean;
  numeroEmpleado: string;
  intentos_api: number;
  intentos_aviso: number;
  private INTENTOS_API = 2;
  activoli = false;
  idProductoRespuesta = '';
  parent: AppComponent;
  status: number;
  recompra: string;
  intentos: number;
  intentosBuro = 0;
  pais:any;
  validacionCrediticia;
  opcionEnvio;
  estatus;
  clausula;
  constructor( parent: AppComponent,
    private userService: UserService,
    private apiValidacionesService: ApiValidacionesService,
    private homeService: HomeService,
    private apiDocumentos: ApiDocumentosService,
    private tokenService: TokenApiService,
    private valida: ValidacionErrors,
    private router: Router,
    private archivoService: ArchivoService,
    private consentimientoService: ApiConsentimientoService,
    private apiAdEService: ApiAdelantoService,
    private dialog: MatDialog) {
    this.parent = parent;
  }

  ngOnInit() {
    setTimeout(() => {
      this.pais= sessionStorage.getItem('pais');
          }, 1);
    this.homeInicio();
    const uri = window.location.href;
    if (uri.indexOf('divisas') !== -1) {
      setTimeout(() => {
        this.showHomeOption(4);
      },500);
      
    }else if(uri.indexOf('venta-empleado') !== -1){
      setTimeout(() => {
        this.showHomeOption(5);
      },500);
    }else if(uri.indexOf('credito-personal') !== -1){
      setTimeout(() => {
        this.showHomeOption(9);
      },500);
    }else if(uri.indexOf('tarjeta-oro') !== -1){
      setTimeout(() => {
        this.showHomeOption(3);
      },500);
    }else if(uri.indexOf('adelanto-efectivo') !== -1){
      setTimeout(() => {
        this.showHomeOption(1);
      },500);
    }else if(uri.indexOf('tpremia') !== -1){
      setTimeout(() => {
        this.showHomeOption(11);
      },500);
    }else if(uri.indexOf('credi-auto') !== -1){
      setTimeout(() => {
        this.showHomeOption(12);
      },500);
    }else if(uri.indexOf('inversion-activa') !== -1){
      setTimeout(() => {
        this.showHomeOption(13);
      },500);
    }else if(uri.indexOf('guatemala') !== -1){

        this.showHomeOption(14);
    }else if(uri.indexOf('presta-prenda') !== -1){
      setTimeout(() => {
        this.showHomeOption(16);
      },500);
    }

    this.homeService.openADE.subscribe(llamarMetodoADE => {
      if (llamarMetodoADE) {
        this.showHomeOption(1);
        this.validacionAvisoPrivacidad(1);

      }
    });

    this.homeService.openCP.subscribe(llamarMetodoCP => {
      if (llamarMetodoCP) {
        this.showHomeOption(2);
        this.validacionAvisoPrivacidad(2);

      }
    });
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.parent.headerComponent.quitarProducto();
      this.parent.headerComponent.showCreditosBreadCrumbs();
      this.parent.headerComponent.cambiarTitulosProductoGral('');
      this.idProductoRespuesta = sessionStorage.getItem('idProductoRespuesta') !== undefined
        && sessionStorage.getItem('idProductoRespuesta') != null ? sessionStorage.getItem('idProductoRespuesta') : '';
      if (this.idProductoRespuesta !== '') {
        this.showHomeOption(7);
        sessionStorage.removeItem('idProductoRespuesta');
        sessionStorage.removeItem('producto_solicitud');
      }
    });

  }

  quitarMisCreditos() {
    this.parent.headerComponent.quitarProducto();
    this.parent.headerComponent.showCreditosBreadCrumbs();
    this.parent.headerComponent.cambiarTitulosProductoGral('Créditos');
  }

  ngOnDestroy(): void {
    this.parent.bodyComponent.ocultarBody('block', 'body', 'head');
    this.parent.headerComponent.validarSesion();

  }

  corregirHeader() {
    const encabezado = document.getElementById('head-d');
    if (encabezado !== undefined) {
      encabezado.removeAttribute('id');
      encabezado.setAttribute('id', 'head');
    }

    const contenido = document.getElementById('body-d');
    if (contenido !== undefined) {
      contenido.removeAttribute('id');
      contenido.setAttribute('id', 'body');
    }
  }

  continuarConProducto(producto: any, ruta: any) {
    if (window.sessionStorage) {
      sessionStorage.setItem('producto_id', producto);
    }
    this.parent.continuarConProducto(producto, ruta);
  }

  corregirHome() {

    const encabezado = document.getElementById('head-d');
    if (encabezado != undefined) {
      encabezado.removeAttribute('id');
      encabezado.setAttribute('id', 'head');
    }

    const contenido = document.getElementById('body-d');
    if (contenido != undefined) {
      contenido.removeAttribute('id');
      contenido.setAttribute('id', 'body');
    }
  }

  homeInicio(): void {
    this.showHomeContent = 0;
    this.showResumen = false;
    this.intentos_api = 0;
    this.intentos_aviso = 0;
    this.corregirHome();
    this.parent.bodyComponent.ocultarBody('none', 'body-d', 'head');
  }

  showHomeOption(option: number): void {
    this.intentos_aviso = 0;
    this.parent.headerComponent.ponerProducto();
    this.parent.headerComponent.ponerLogoBAZ();
    switch (option) {
      case 1:
        this.parent.headerComponent.showCreditosBreadCrumbs();
        this.showHomeContent = 1;
        this.parent.headerComponent.cambiarTitulosProducto('Adelanto de Efectivo', 'Adelanto de Efectivo','Créditos');
        break;
      case 2:
        this.parent.headerComponent.showCreditosBreadCrumbs();
        this.showHomeContent = 2;
        this.parent.headerComponent.cambiarTitulosProducto('Crédito Personal', 'Crédito Personal','Créditos');
        break;
      case 3:
        this.parent.headerComponent.showCreditosBreadCrumbs();
        this.showHomeContent = 3;
        this.parent.headerComponent.cambiarTitulosProducto('Tarjeta de Crédito Oro para Colaboradores GS', 'Tarjeta Oro Colaboradores','Créditos');
        break;
      case 4:
        this.parent.headerComponent.showCreditosBreadCrumbs();
        this.showHomeContent = 4;
        this.parent.headerComponent.cambiarTitulosProducto('Divisas GS', 'Divisas GS','Otros Beneficios');
        break;
      case 5:
        this.parent.headerComponent.showCreditosBreadCrumbs();
        this.showHomeContent = 5;
        this.parent.headerComponent.cambiarTitulosProducto('Venta Empleado', 'Venta Empleado','Créditos');
        break;          
      case 7:
        if (!this.userService.recoverToken() && !this.tokenService.validarToken()) {
          this.parent.headerComponent.onManejoSesion();
        } else {
          this.showHomeContent = 7;
          this.parent.headerComponent.cambiarTitulosProducto('Mis Créditos', 'Mis Créditos','Créditos');
        }
        break;
      case 8:
        this.showHomeContent = 8;
        this.parent.headerComponent.cambiarTitulosProducto('Adelanto de Efectivo', 'Adelanto de Efectivo','Créditos');
        break;
      case 9:
        this.parent.headerComponent.showCreditosBreadCrumbs();
        this.showHomeContent = 9;
        this.parent.headerComponent.cambiarTitulosProducto('Crédito Personal GS', 'Crédito Personal GS','Créditos');
        break;
      case 11:
        this.parent.headerComponent.showCreditosBreadCrumbs();
        this.showHomeContent = 11;
        this.parent.headerComponent.cambiarTitulosProducto('TPremia GS', 'TPremia GS','Otros Beneficios');
        this.parent.headerComponent.quitarLogoBAZ();
        break;
      case 12:
          this.parent.headerComponent.showCreditosBreadCrumbs();
          this.showHomeContent = 12;
          this.parent.headerComponent.cambiarTitulosProducto('Crédito Auto GS', 'Crédito Auto GS','Créditos');
          break;
      case 13:
          this.parent.headerComponent.showCreditosBreadCrumbs();
          this.showHomeContent = 13;
          this.parent.headerComponent.cambiarTitulosProducto('Inversión Activa Grupo Salinas', 'Inversión Activa Grupo Salinas','Otros Beneficios');
          break;
      case 14:
          this.parent.headerComponent.showCreditosBreadCrumbs();
          this.showHomeContent = 14;
          this.parent.headerComponent.cambiarTitulosProducto('', 'Guatemala','Beneficios');
           break;
      case 16:
          this.parent.headerComponent.showCreditosBreadCrumbs();
          this.showHomeContent = 16;
          this.parent.headerComponent.cambiarTitulosProducto('Presta Prenda', 'Presta Prenda','Otros Beneficios');
          break;    
      default:
        this.showHomeContent = 0;
        this.parent.headerComponent.cambiarTitulosProducto('', '', '');
        break;
    }
  }

  validacionAvisoPrivacidad(idProducto: number): void {
    this.numeroEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    if (this.userService.recoverToken() !== undefined && this.userService.recoverToken() !== '') {
      this.apiValidacionesService.getValidacionesHome(this.numeroEmpleado, 4, idProducto).subscribe((response) => {
        this.intentos_api = 0;
        if (response.resultado.requerido === true) {
          sessionStorage.removeItem('producto_id');
          if (this.intentos_aviso >= 2) {
            this.homeInicio();
            sessionStorage.removeItem('producto_id');
          } else {
            this.openAvisoPrivacidad(idProducto);
          }
        } else {
          if (idProducto === TipoProducto.ADELANTO_EFECTIVO) {
            this.validacionAdE([2, 3, 8, 9, 10, 11]);
          } else if (idProducto === TipoProducto.PRESTAMO) {
            this.validacionCP(2);
          }
        }
      }, (error) => {
        this.errorAPIConexion(error, 4, null, idProducto);
        console.log('Error en validaciones: ' + JSON.stringify(error));
      });
    } else {
      // Borramos los residuos de información
      this.userService.cierreSesion();
      // Ingresamos el producto al que entraremos
      sessionStorage.setItem('producto_id', '1');
      // Llamamos al componente de inicio de sesión
      this.userService.iniciarSesion();
    }
  }

  eventoSalir(event): void {
    this.homeInicio();
  }

  openAvisoPrivacidad(idProducto: number): void {
    this.apiDocumentos.getObtenerDocumentos(this.numeroEmpleado, ['392']).subscribe((response) => {
      const documentos = response.resultado.documentos;
      for (let i = 0; i < documentos.length; i++) {
        if (documentos[i].extension !== 'null') {
          const nombre = documentos[i].nombre + '.' + documentos[i].extension;
          const contentType = this.archivoService.validarTipoImagen(documentos[i].extension);
          const base64 = documentos[i].base64;
          const blob = this.archivoService.b64toBlob(base64, contentType, null);
          const dialogRef = this.dialog.open(DialogOverview, {
            width: '750px',
            height: '95vh',
            data: {
              titulo: 'Aviso de Privacidad',
              urlPdf: blob,
              showDownloadButton: true,
              disableClose: true,
            },
            autoFocus: false
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
              this.guardarConsentimientos(0, idProducto);
            } else {
              this.guardarConsentimientos(1, idProducto);
            }
          });
        }
      }
    },
      (error) => {
        this.errorAPIConexion(error, 5, null, TipoProducto.ADELANTO_EFECTIVO);
      });
  }

  guardarConsentimientos(status: number, idProducto: number) {
    const request = new ConsentimientoModel();
    request.numeroEmpleado = this.numeroEmpleado;
    request.idClausula = TipoDocumento.AVISO_DE_PRIVACIDAD;
    request.idProducto = idProducto;
    request.estatus = status;
    this.consentimientoService.postConsentimiento(request).subscribe(() => {
      if (status === 1) {
        if (idProducto === TipoProducto.ADELANTO_EFECTIVO) {
          this.validacionAdE([2, 3, 8, 9, 10,11]);
        } else if (idProducto === TipoProducto.PRESTAMO) {
          this.validacionCP(2);
        }
      } else {
        this.openDialogMsg('Para continuar con tu solicitud, es necesario aceptar el Aviso de Privacidad.', 1, idProducto);
      }
    },
      (error) => {
        this.status = status;
        this.errorAPIConexion(error, 3, null, TipoProducto.ADELANTO_EFECTIVO);
      });
  }

  validacionAdE(validaciones): void {
    this.apiValidacionesService.getValidacionesHome(this.numeroEmpleado, validaciones, TipoProducto.ADELANTO_EFECTIVO)
      .subscribe((response) => {
        this.intentos_api = 0;
        if (response.resultado.requerido === false) {
          this.openDialogDuracion(1, false);
        } else if (response.resultado.requerido === true) {
         if (response.resultado.idValidacion === 11) {
          this.apiDocumentos.getObtenerDocumentos(this.numeroEmpleado, ['' + TipoDocumento.IDENTIFICACION_OFICIAL,
          '' + TipoDocumento.COMPROBANTE_DE_DOMICILIO])
            .subscribe((response) => {
              let request = new RequestDocumentoModel();
              request.numeroEmpleado = this.numeroEmpleado;
              request.idProducto = 1;
              request.documentos = response.resultado.documentos;
              this.apiDocumentos.postGuardarDocumentos(request).subscribe(documentos => {
                this.openDialogDuracion(1, true);
              },
              (error) => {
                this.openDialogDuracion(1, true);
              });
            },
            (error) => {
              if (error.status === 404) {
                this.mostrarModalExpediente(1)
              } else {
                this.openDialogDuracion(1, true);
              }
            }) 
          

         } else
          if (response.resultado.mensaje.indexOf('es necesario que adjuntes tus documentos') !== -1) {
            this.apiAdEService.getAdelanto(this.numeroEmpleado, 2).subscribe((response1) => {
              console.log(response1);
              if (response1.resultado.adelantos[0].expedientes != null && response1.resultado.adelantos[0].expedientes !== undefined) {
                sessionStorage.setItem('expedientes', JSON.stringify(response1.resultado.adelantos[0].expedientes));
              }
              this.openDialogCondicionado(response1.resultado.adelantos[0].idAdelanto);
            });
          } else {
            sessionStorage.removeItem('producto_id');
            this.openDialogMsg(response.resultado.mensaje);

          }
        }
      }, (error) => {
        this.errorAPIConexion(error, 1, validaciones, TipoProducto.ADELANTO_EFECTIVO);
        console.log('Error en validaciones: ' + JSON.stringify(error));
      });
  }

  validarMostrarModal(producto: any): void {
    if (window.sessionStorage) {
      sessionStorage.setItem('producto_id', producto);
    }
    this.apiValidacionesService.getValidaciones(5, this.numeroEmpleado).subscribe((response) => {
      this.intentos = 1;
      this.abrirModal(response.resultado.requerido);
    }, (error) => {
      this.errorAPIConexion(error, 6);
    });
  }

  abrirModal(validacionCrediticia): void {
    console.log("/////////////// "+validacionCrediticia)
    if (validacionCrediticia === true) {
      this.apiDocumentos.getObtenerDocumentos(this.numeroEmpleado, ['' +
        TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO]).subscribe((response) => {
          this.intentos = 1;
          const documentos = response.resultado.documentos;
          // Metodo para recuperar los archivos de BD CON
          for (let i = 0; i < documentos.length; i++) {
            if (this.archivoService.validarTipoImagen(documentos[i].extension)) {
              const nombre = documentos[i].nombre + '.' + documentos[i].extension;
              // setear el valor del tipo de contenido
              const contentType = this.archivoService.validarTipoImagen(documentos[i].extension);
              // Obtener el base 64
              const base64 = documentos[i].base64;
              // Convertir it to a blob to upload
              const blob = this.archivoService.b64toBlob(base64, contentType, null);
                const dialogRef = this.dialog.open(DialogOverview, {
                  width: '750px',
                  height: '95vh',
                  data: {
                    titulo: 'Investigación Crediticia',
                    urlPdf: blob,
                    showDownloadButton: false,
                    disableClose: true
                  },
                  autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                  if (result) {
                    this.intentosBuro++;
                    this.guardarConsentimientosBuro(1, EstatusConsentimiento.ESTATUS_NO_APROBADO,
                      TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO);
                  } else {
                    this.guardarConsentimientosBuro(2, EstatusConsentimiento.ESTATUS_APROBADO,
                                                    TipoDocumento.AUTORIZACION_DE_CONSULTA_A_BURO);
                  }
                });

            }
          }
        }, (error) => {
          this.validacionCrediticia = validacionCrediticia;
          this.errorAPIConexion(error, 7);
        });

    } else {
      this.openDialogRecompra();
    }
  }

  guardarConsentimientosBuro(opcionEnvio: number, estatus: number, clausula: number) {

    const request = new ConsentimientoModel();
    request.numeroEmpleado = this.numeroEmpleado;
    request.idClausula = clausula;
    request.idProducto = 1;
    request.estatus = estatus;
    this.consentimientoService.postConsentimiento(request).subscribe(response => {
      this.intentos = 1;
      switch (opcionEnvio) {
        case 1: this.openDialogMsgBuro('Recuerda que es necesario contar con tu autorización para realizar la investigación' +
          ' crediticia para continuar con tu solicitud', true); break;
        case 2: this.openDialogRecompra(); break;
      }

    }, (error) => {
      this.opcionEnvio = opcionEnvio;
      this.estatus = estatus;
      this.clausula = clausula;
      this.errorAPIConexion(error, OpcionesIntentosAltaDocumentos.GUARDAR_CONSENTIMIENTOS);
    });

  }

  /* Se realizan todas las validaciones de Prestamo y se manda llamar recursivamente el metodo
  por cada una de las validaciones inicialmente se manda llamar este metodo con la validacion 2
  */
  validacionCP(idValidacion: number): void {
    this.numeroEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    this.apiValidacionesService.getValidacionesHome(this.numeroEmpleado, idValidacion, TipoProducto.PRESTAMO).subscribe((response) => {
      this.intentos_api = 0;
      if (response.resultado.mensaje === 'El cliente es elegible') {
        switch (idValidacion) {
          // Validacion de Horario exitosa pasa a DS para CP
          case 1: this.continuarConProducto(2, '/datossocio'); break;
          // Validacion de Requisitos exitosa pasa validar Pedidos
          case 2: this.validacionCP(3); break;
          // Validacion de Pedidos exitosa pasa validar CeCos
          case 3: this.validacionCP(8); break;
          // Validacion de CeCos exitosa pasa validar CP *Por el momento pasa a horario
          case 8: this.validacionCP(1); break;
          // Validacion de CeCos exitosa pasa validar CP

          // case 8: this.validacionCP(9); break;
          // Validacion de CP exitosa pasa validar Horario
          case 9: this.validacionCP(1); break;
        }
      } else {
        switch (idValidacion) {
          // Validacion de Horario NO exitosa pasa a DS para CP
          case 1: //this.openDialog(2); break;
          // Validacion de Requisitos NO exitosa manda TOOL TIP
          case 2: {
            this.activoli = true;
            setTimeout(() => {
              this.activoli = false;
            }, 2000); break;
          }
          // Validacion de Pedidos NO exitosa manda Modal
          case 3: { this.openDialogMsg('No cumples con las validaciones para continuar'); break; }
          // Validacion de CeCos NO exitosa manda Modal
          case 8: {
            this.openDialogMsg('Error en la consulta de tu información' +
              'Escríbenos para reportar tu caso a:' +
              ' beneficios.financieros@gruposalinas.com.mx'); break;
          }
          // Validacion de CP NO exitosa manda Modal
          case 9: {
            this.openDialogMsg('Tienes un Credito Personal en validación. ' +
              'Podrás solicitar otro cuando hayas cubierto su pago.', 2); break;
          }
        }
      }
    },
      (error) => {
        this.errorAPIConexion(error, idValidacion, null);
        console.log('Error en validaciones: ' + JSON.stringify(error));
      });
  }
  /*
    errorAPIConexion(error, option: number, idProducto?: number): void {
      if ((error.status === 500 || error.status === 504) && this.intentos_api >= this.INTENTOS_API) {
        this.intentos_api = 0;
        this.dialog.closeAll();
        this.openDialogMsgError('Ocurrio un error al conectar con los servicios. Por favor intente más tarde', true);
      } else {
        if (error.status === 500 && this.intentos_api < this.INTENTOS_API) {
          this.dialog.closeAll();
          this.intentos_api++;
          this.openDialogReintentar('Ocurrio un error al realizar la consulta', 'Reintentar', option, idProducto);
        }
      }
    }
  */
  errorAPIConexion(error, option: number, validaciones?: Array<number>, idProducto?: number): void {
    if (error.status === 400 || (error.status > 402 && error.status < 500)) {
      this.dialog.closeAll();
      this.openDialogMsgError(this.valida.getError(error), true);
    } else if (error.status === 500 && this.intentos_api >= this.INTENTOS_API) {
      sessionStorage.removeItem('producto_id');
      this.intentos_api = 0;
      this.dialog.closeAll();
      this.openDialogMsgError('Ocurrio un error al conectar con los servicios. Por favor intente más tarde', true);
    } else {
      if (error.status === 500 && this.intentos_api < this.INTENTOS_API) {
        this.dialog.closeAll();
        this.intentos_api++;
        this.openDialogReintentar('Ocurrio un error al realizar la consulta', 'Reintentar', option, validaciones, idProducto);
      }
    }
  }
  openDialogDuracion(idProducto, banderaRecompra): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message:
          'Cuentas con 30 minutos para realizar tu ' +
          'solicitud de Adelanto de Efectivo'
      },
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (banderaRecompra === true) {
        this.validarMostrarModal(idProducto);
      } else {
        this.continuarConProducto(idProducto, '/datossocio');
      }
    });
  }

  mostrarModalExpediente(idProducto): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message:
          'Estimado usuario. <br> Detectamos que es necesario actualizar tu expendiente, ' +
          'por favor adjunta tu comprobante de domicilio e identificación vigentes '
      },
      autoFocus: false,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
        this.continuarConProducto(idProducto, '/datossocio');
    });
  }
  openDialogMsgError(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
  }

  openDialogReintentar(msg: string, boton: string, option: number, validaciones?: Array<Number>, idProducto?: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      switch (option) {
        case 1: { this.validacionAdE(validaciones); break; }
        case 2: { this.validacionCP(2); break; }
        case 3: { this.guardarConsentimientos(this.status, idProducto); break; }
        case 4: { this.validacionAvisoPrivacidad(idProducto); break; }
        case 5: { this.openAvisoPrivacidad(idProducto); break; }
        case 6: { this.validarMostrarModal(idProducto); break; }
        case 7: { this.abrirModal(this.validacionCrediticia); break; }

      }
    });
  }


  openDialogRecompra(): void {
    const dialogRef = this.dialog.open(DialogRecompraComponent, {
      width: '500px',
      data: {
        message: 'Tienes un Adelanto de Efectivo en proceso, es necesario que actualices tus documentos.',
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (window.sessionStorage) {
          sessionStorage.producto_solicitud = 1;
        }
        this.continuarConProducto(1, '/configurador');
      } else {
        if (window.sessionStorage) {
          sessionStorage.producto_solicitud = 1;
        }
        this.continuarConProducto(1, '/datossocio');      }
    });
  }

  openDialogCondicionado(idAdelanto): void {
    const dialogRef = this.dialog.open(DialogCondicionadoComponent, {
      width: '500px',
      data: {
        message: 'Tienes un Adelanto de Efectivo en proceso, es necesario que actualices tus documentos.',
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (window.sessionStorage) {
          sessionStorage.producto_solicitud = 1;
          sessionStorage.idProductoRespuesta = idAdelanto;
        }
        this.continuarConProducto(1, '/altadocumentos');
      } else {
        sessionStorage.removeItem('producto_id');
      }
    });
  }

  openDialogMsgBuro(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route && this.intentosBuro === 2) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      } else if (route && this.intentosBuro < 2) {
        this.validarMostrarModal("1");
      }
    });
  }

  openDialogMsg(msg: string, option?: number, idProducto?: number): void {
    const dialogMsg = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg.replace(/\n/g, '<br/>'),
        disableClose: true
      },
      autoFocus: false
    });
    dialogMsg.afterClosed().subscribe(() => {
      if (option !== undefined) {
        switch (option) {
          case 1: {
            this.intentos_aviso++;
            if (idProducto === TipoProducto.ADELANTO_EFECTIVO) {
              this.validacionAvisoPrivacidad(1);
            } else if (idProducto === TipoProducto.PRESTAMO) {
              this.validacionAvisoPrivacidad(2);
            }
            break;
          }
          case 2: {
            this.showHomeContent = 8;
            this.showResumen = true;
            break;
          }
        }
      }
    });
  }
}
