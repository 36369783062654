<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/FOLLETO_TOR.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/INFOGRAFIA_TOR.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    <a class="video" (click)="mostrarVideo()"> Video</a>
    <a class="faqs" href="../../../../../../assets/docs/home/Preguntas_frecuentes_TOR.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡Ahora eres parte de un selecto grupo de Colaboradores!</h1>
<p class="textoverdeTor"><strong>¿Qué es?</strong></p>
<p>Una <strong>tarjeta de crédito</strong>  que te ofrece un Beneficio exclusivo en <strong>la tasa de interés</strong></p>
<p class="textoverdeTor"><strong>¿Para quién es?</strong></p>
<p>Solo para Colaboradores que hayan recibido <strong> invitación </strong>(Chat Zeus/Correo electrónico)</p>
<p class="textoverdeTor"><strong>¿Qué Beneficio exclusivo tiene?</strong></p>
<ul class="contenido">
    <li>Tasa preferencial con <strong>25 % </strong>de descuento<sup>1</sup></li>
    <li>Sin pago de anualidad ni cuota de  <strong>NO</strong> uso</li>
    <li>Compras a <strong>meses sin intereses</strong> en establecimientos participantes</li>
    <li>Disposición en efectivo del <strong>100 % </strong>línea de crédito en efectivo</li>
    <li>Entrega <strong>inmediata </strong>en sucursal gestora <sup>2</sup></li>
    <li>Sin costo, Seguro de saldo deudor, robo y/o extravió</li>
    <li>Sin aval ni garantías</li>
</ul>
<p class="textoverdeTor"><strong>Características</strong></p>
<ul class="contenido">
    <li>Línea de Crédito: desde <strong>$3,000</strong> hasta <strong>$100,000</strong><sup>4</sup></li>
    <li>Frecuencia de <strong>pago mensual</strong></li>
    <li>Pago con <strong>cargo automático</strong> a tu cuenta de nómina</li>
    <li>Tarjeta de crédito con respaldo <strong>Visa/Mastercard</strong></li>
    <li>Fecha de Corte (Consulta la siguiente Tabla)</li>
</ul>
<div  class="container" >
    <div class="row">
        <div  >
           
            <img   class = "imgWidth" src="../../../../assets/img/tabla_tor.png" />
          </div>
    </div>
</div>
<p class="textoverdeTor"><strong>Requisitos</strong></p>
<ul class="contenido">
    <li>Haber recibido un folio a través de invitación <sup>5</sup></li>
    <li>Contar con <strong>línea de crédito activa </strong>en Banco Azteca</li>
    <li>Antigüedad mínima de <strong>2 años</strong></li>
    <li>Tener más de <strong>20 años</strong></li>
    <li><strong>Buen comportamiento</strong> en Buró de Crédito</li>
    <li>Identificación oficial vigente <strong>(INE/IFE o pasaporte)</strong></li>
    <li><strong>Comprobante de domicilio</strong> no mayor a 3 meses</li>
    <li>Recibir <strong>nómina</strong> en Banco Azteca</li>
</ul>



<div class="subindices">
    <p><sup>1</sup> Tasa variable del 70.4% al 30 de enero del 2023</p>
    <p><sup>2</sup> Para mayor información consultar con un Asesor en Sucursal</p>
    <p><sup>3</sup> Fecha de Corte y fecha límite de pago dependerán del día de la entrega de la Tarjeta </p>
    <p><sup>4</sup> Sujeto a evaluación de crédito</p>
    <p><sup>5</sup> Chat Zeus / Correo electrónico</p>
</div>
<div class="terminos">
    <strong><a class="folleto" href="../../../../../../assets/docs/home/TyC_TOR.pdf" target="_blank" rel="noopener noreferrer">Consulta Términos y Condiciones</a></strong>
</div>

<h1 class="subtitleTOR">Comienza a disfrutar tu Tarjeta Oro para Colaboradores GS</h1>

<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
      </div>
      <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
      </div>
</div>
<hr class="prodBottom">
<p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
<p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> 55 7099 1199 <br> Opción 5</p>
<div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
    href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div><br><br><br>

<div class="abrir-modal animacion fadeIn" *ngIf="modalVideo">
    <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <button (click)="cerrarVideo()" type="button" class="close cmodal" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe title="" class="embed-responsive-item"
                        src="../../../../../../assets/videos/Video_TOR.mp4"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>