import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService, UserService, JwtService, ArchivoService } from './services';
import { HttpTokenInterceptor } from './interceptors/http.token.interceptor';
import { CustomHttpInterceptor } from './interceptors/http-interceptor';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AuthGuardAccessTokenService } from './services/auth/auth-guard-token.service';
import { AltaDocumentosService } from './services/credito/altaDocumentos.service';
import { ApiCreditosService } from './services/credito/api.creditos/api.creditos.service';
import { ValidacionUtils } from './services/utileria/validaciones.service';
import { ValidacionErrors } from './services/utileria/validacion.error';
import { DialogModule } from '../shared/components/modales/modales.module';
import { TokenApiService } from './services/token.service';
import { HomeService } from './services/home.service';
@NgModule({
  imports: [
    CommonModule,
    DialogModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    AuthGuardService,
    AuthGuardAccessTokenService,
    ApiService,
    AltaDocumentosService,
    ApiCreditosService,
    JwtService,
    UserService,
    HomeService,
    ArchivoService,
    TokenApiService,
    ValidacionUtils,
    ValidacionErrors
  ]
})
export class CoreModule {}
