<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="faqs" href="../../../../../../assets/docs/home/faqs_Inversion_activa.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡Haz crecer tu dinero!</h1>
<p class="textoverdeTor"><strong>¿Qué es?</strong></p>
<p>Inversión Activa Grupo Salinas es una cuenta, creada para ayudarte y hacer crecer tu dinero, donde lo tienes disponible 24/7.</p>
<p class="textoverdeTor"><strong>¿Para quién es?</strong></p>
<p>Para todos los Colaboradores de Grupo Salinas</p>
<p class="textoverdeTor"><strong>¿Qué beneficios exclusivos te ofrece?</strong></p>
<ul class="contenido">
    <li>Tasa preferencial (de las más atractivas del mercado)</li>
    <li>Disposición de tu dinero 24/7</li>
    <li>Transferir dinero</li>
    <li>No cobra comisiones, ni solicita saldo promedio mínimo</li>

</ul>

<p class="textoverdeTor"><strong>Características</strong></p>
<ul class="contenido">
    <li>Ábrela facilmente desde la App o portal de Banco Azteca los 365 días del año</li>
    <li>Monto mínimo de $1,000</li>
    <li>Disponible hasta para 9 familiares</li>
    
</ul>



<p class="textoverdeTor"><strong>Requisitos</strong></p>
<ul class="contenido">
    <li>Contar con cuenta de Nómina Grupo Salinas de Banco Azteca </li>
    <li>Tener expediente actualizado (Para verificarlo acude a cualquier sucursal de Banco Azteca y acércate con un Ejecutivo)</li>
</ul>


<p class="textoverdeTor"><strong>Requisitos para familiares</strong></p>
<ul class="contenido">
    <li>Acudir a Sucursal </li>
    <li>Abrir una cuenta Socio GS</li>
    <li>Llevar INE y comprobante de domicilio</li>
    <li>Número de cuenta del Colaborador</li>
    <li>El número de compañía donde al Colaborador le pagan la nómina (los 4 dígitos que se encentran en tu recibo de nómina) <sup>1</sup></li>
</ul>





    <div class="subindices">
        <p><sup><strong>1</strong></sup>Este dato lo podrás consultar en el recibo de nómina a través de Zeus</p>
    </div>


    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
          </div>
          <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
          </div>
    </div>
    <hr class="prodBottom">
    <p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
    <p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> <strong>55 7099 1199</strong> <br> Opción <strong>5</strong></p>
    <div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
        href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>
