import { Component, Host, OnInit } from '@angular/core';
import { HomeComponent } from '../../home.component';
import { ApiCreditosService, ArchivoService, UserService } from 'src/app/core';
import { ApiDocumentosService } from 'src/app/core/services/credito/api.documentos/api.documentos';
import { TipoProducto, TipoDocumento } from 'src/app/core/services/utileria/utileria.enum';
import { DialogOverviewMsg } from 'src/app/shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogReintentarComponent } from 'src/app/shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { ValidacionErrors } from 'src/app/core/services/utileria/validacion.error';

@Component({
  selector: 'app-miscreditos',
  templateUrl: './home.miscreditos.html',
  styleUrls: ['./home.miscreditos.scss']
})

export class HomeMisCreditosComponent implements OnInit {

  showDocuments: boolean;
  numEmpleado: string;
  intentos_api: number;
  valida: ValidacionErrors;
  parentHome: HomeComponent;
  contratoPdfADE;
  avisoPrivacidad;
  public visible = {0: true};
  private INTENTOS_API = 3;
  error: any;
  page = 1;
  rotation = 0;
  zoom = 1.0;
  originalSize = true;
  pdf: any;
  renderText = true;
  isLoaded = false;
  stickToPage = false;
  showAll = true;
  autoresize = true;
  fitToPage = false;
  outline: any[];
  HEADER_DATA_ADELANTO = [
    'Adelanto de E. N°:',
    'Monto solicitado:',
    'Fecha de solicitud:',
    'Comisión por disposición 1%:',
    'Fecha de cobro:',
    'IVA de la comisión*:',
    '',
    'Monto total a pagar:',
    'Ver Documentos Legales >'
  ];
  ELEMENT_DATA_ADELANTO = [];
  HEADER_DATA_PRESTAMO = [
    'Crédito Personal N°:',
    'Monto solicitado:',
    'Fecha de solicitud:',
    'Plazo:',
    'Tasa fija anual:',
    'Fecha de Cobro:',
    'Ver Documentos Legales >'
  ];
  ELEMENT_DATA_PRESTAMO = [];

  constructor(
    private userService: UserService,
    private apiCreditos: ApiCreditosService,
    private apiDocumentos: ApiDocumentosService,
    private archivoService: ArchivoService,
    private router: Router,
    private dialog: MatDialog,
     parentHome: HomeComponent
  ) {
    this.parentHome = parentHome;
    this.valida = new ValidacionErrors();
  }

  ngOnInit(): void {
    this.intentos_api = 0;
    this.showDocuments = false;
    this.numEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    this.obtenerCreditosInfo();
  }

  mostrarDocumentosLegales(idAdelanto: number): void {
    console.log('Id adelanto: ' + idAdelanto);
    this.showDocuments = true;

  }

  obtenerCreditosInfo(): void {
    console.log('getAdelantoData');
    const numEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    this.apiCreditos.getCreditosSocio(numEmpleado).subscribe((data) => {
      console.log(data);
      if (data.resultado.creditos.length > 0) {
        this.ELEMENT_DATA_ADELANTO = (data.resultado.creditos[0].detalle);
        if (data.resultado.creditos.length > 1) {
          this.ELEMENT_DATA_PRESTAMO = (data.resultado.creditos[1].detalle);
        }
      }
    }, error => {
      this.errorAPIConexion(error, 1);
    });
  }

  errorAPIConexion(error, option: number): void {
    if (error.status === 500 && this.intentos_api >= this.INTENTOS_API) {
      this.intentos_api = 0;
      this.dialog.closeAll();
      this.openDialogMsg('Ocurrio un error al conectar con los servicios. Por favor intente más tarde', true);
    } else {
      this.dialog.closeAll();
      if (error.status === 500 && this.intentos_api < this.INTENTOS_API) {
        this.intentos_api++;
        this.openDialogReintentar('Ocurrio un error al realizar la consulta', 'Reintentar', option);
      } else if (error.status === 404) {
        this.openDialogMsg('No tienes créditos activos', true);
      } else if (error.status === 400 || (error.status > 402 && error.status < 500)) {
        this.openDialogMsg(this.valida.getError(error), false);
      }
    }
  }

  openDialogReintentar(msg: string, boton: string, option: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      switch (option) {
        case 1: this.obtenerCreditosInfo(); break;
      }
    });
  }

  mostrarDocumentos(idProducto: string, idCredito: any) {
    this.showDocuments = true;
    this.hideOtherPDFs();
    setTimeout(() => {
      this.visible[0] = true;
    }, 100);
    this.apiDocumentos
      .getObtenerExpedientes(this.numEmpleado, idCredito, idProducto)
      .subscribe(response => {
        const documentos = response.resultado.documentos;
        console.log(documentos);
        for (let i = 0; i < documentos.length; i++) {
          if (documentos[i].ext !== 'null') {
            const contentType = this.archivoService.validarTipoImagen(documentos[i].ext);
            const base64 = documentos[i].base64;
            const blob = this.archivoService.b64toBlob(base64, contentType, null);
            if (documentos[i].idDocumento === 498) {
              this.contratoPdfADE = URL.createObjectURL(blob);
            } else if (documentos[i].idDocumento === 392)  {
              this.avisoPrivacidad = URL.createObjectURL(blob);
            }
          }
        }
      }, err => {
        console.log('Error al intentar obtener los documentos');
        this.showDocuments = false;
        this.openDialogMsg(
          'Ocurrio un error al conectar con los servicios.', false
        );
      }
      );
  }

  activateTab(tab: number): void {
    this.hideOtherPDFs();
    setTimeout(() => {
      this.visible[tab] = true;
    }, 100);
  }

  hideOtherPDFs(): void {
    this.visible[0] = false;
    this.visible[1] = false;
  }

  openDialogMsg(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(() => {
      if (route) {
        this.router
        .navigateByUrl('/#', { skipLocationChange: true })
        .then(() => this.router.navigate(['/home']));
      }
    });
  }

  exitFunction(): void {
    if (!this.showDocuments) {
      this.parentHome.homeInicio();
      this.parentHome.quitarMisCreditos();
    } else {
      //this.hideOtherPDFs();
      this.showDocuments = false;
      this.contratoPdfADE = null;
      this.avisoPrivacidad = null;
    }
  }

}
