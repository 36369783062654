import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Lugar } from './lugar';
import { HttpClient } from '@angular/common/http';
import { MapsAPILoader } from '@agm/core';
import { DialogOverviewMsg } from '../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { MatDialog } from '@angular/material/dialog';

enum MapaEnum {
  STATUS_OK = 'OK',
  PRESTAMO = 'préstamo'
}
@Component({
  selector: 'app-mapa-ggl',
  template: `<div #map id="map"></div>`,
  styleUrls: ['./mapa.component.css']
})
export class MapaGoogleComponent implements OnInit {
  @Input() direccionMapa: string;
  @ViewChild('map', { static: true }) mapaElement: ElementRef;
  map: google.maps.Map;
  marcador: google.maps.Marker;
  marcadorAux: any;
  infoWindows: google.maps.InfoWindow;
  geocoder: google.maps.Geocoder;
  address = '';
  coordenadas: Lugar;
  mapaCargadoCorrectamente: boolean;
  localidad: string;
  municipio: string;
  calle: string;
  estado: string;
  constructor(
    private http: HttpClient,
    public mapLoader: MapsAPILoader,
    private dialog: MatDialog,
  ) { this.mapaCargadoCorrectamente = false; }

  ngOnInit() {
    this.crearComponenteMapa();
  }

  crearComponenteMapa() {
    this.mapLoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
      this.cargarMapa();
      this.mapaCargadoCorrectamente = true;
    }).catch(error => {
    });
  }

  cargarMapa() {
    const latLng = new google.maps.LatLng(19.432602, -99.133205);

    const mapaOpciones: google.maps.MapOptions = {
      zoom: 18,
      center: latLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapaElement.nativeElement, mapaOpciones);
    this.map.addListener('click', (coors) => {

      const nuevoMarcador: Lugar = {
        nombre: 'Ubicación domicilio.',
        lat: coors.latLng.lat(),
        lng: coors.latLng.lng(),
        id: new Date().toISOString()
      };
      const latLngClick = new google.maps.LatLng(nuevoMarcador.lat, nuevoMarcador.lng);
        this.map.setCenter(latLngClick);
        this.map.setZoom(18);
      this.agregarMarcador(nuevoMarcador);
      this.coordenadas = nuevoMarcador;
      this.geocodeLatLng(this.geocoder, this.map, this.infoWindows);
    });

  }

  agregarMarcador(marcador: Lugar) {
    if (this.marcador !== undefined) {

      this.marcador.setMap(null);
    }
    const latLng = new google.maps.LatLng(marcador.lat, marcador.lng);
    this.marcador = new google.maps.Marker({
      map: this.map,
      animation: google.maps.Animation.DROP,
      position: latLng,
      draggable: true,
      title: marcador.id
    });
    const contenido = `<b>${marcador.nombre}</b>`;
    const infoWindow = new google.maps.InfoWindow({
      content: contenido
    });
    this.coordenadas = marcador;
    this.infoWindows = infoWindow;

    google.maps.event.addDomListener(this.marcador, 'click', () => {
      infoWindow.open(this.map, this.marcador);
    });

    google.maps.event.addDomListener(this.marcador, 'dblclick', (coors: any) => {
      infoWindow.open(this.map, this.marcador);

    });


    google.maps.event.addDomListener(this.marcador, 'dragend', (coors: any) => {
      const nuevoMarcador = {
        lat: coors.latLng.lat(),
        lng: coors.latLng.lng(),
        nombre: marcador.nombre,
        id: marcador.id
      };
      this.coordenadas = nuevoMarcador;
      const latLngDrag = new google.maps.LatLng(nuevoMarcador.lat, nuevoMarcador.lng);
        this.map.setCenter(latLngDrag);
        this.map.setZoom(18);
      this.geocodeLatLng(this.geocoder, this.map, this.infoWindows);
    });
  }

  geocodeAddressUpdate(direccionMapa, calle, localidad, municipio, estado) {
    this.direccionMapa = direccionMapa;
    this.localidad = localidad;
    this.municipio = municipio;
    this.calle = calle;
    this.estado = estado;
    this.geocodeAddress(this.geocoder, this.map);
  }

  geocodeAddress(geocoder, resultsMap) {
    geocoder.geocode({ 'address': this.direccionMapa }, (results, status) => {
      if (status === MapaEnum.STATUS_OK) {
        if (results.length > 1) {
          console.log(results)
          const ubicacion = this.validarDireccionGeocoder(results);
          console.log(ubicacion);
          this.map.setCenter(results[ubicacion.ubicacion].geometry.location);
          this.map.setZoom(18);
          const nuevoMarcador: Lugar = {
            nombre: 'Ubicación domicilio',
            lat: results[ubicacion.ubicacion].geometry.location.lat(),
            lng: results[ubicacion.ubicacion].geometry.location.lng(),
            id: new Date().toISOString()
          };
          this.coordenadas = nuevoMarcador;
          this.agregarMarcador(nuevoMarcador);
        } else {
          console.log(results);

          this.map.setCenter(results[0].geometry.location);
          this.map.setZoom(18);
          const nuevoMarcador: Lugar = {
            nombre: 'Ubicación domicilio',
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
            id: new Date().toISOString()
          };
          this.marcadorAux = nuevoMarcador;
          this.coordenadas = nuevoMarcador;
          this.agregarMarcador(nuevoMarcador);
        }

      }
    });
  }

 private validarDireccionGeocoder(direcciones: any): any  {
  const array = new Array<number>(direcciones.length);
  let contador: number;
  contador = 0;
  direcciones.forEach(direccion => {
      array[contador] = 0;
      if (this.removeAccents(direccion.formatted_address.toLowerCase()).indexOf(this.removeAccents(this.municipio.toLowerCase())) !== -1) {
        array[contador] = array[contador] + 1;
      }

      direccion.address_components.forEach(componenteDir => {
        if (componenteDir.types.length > 1 && componenteDir.types[1] === 'sublocality') {
          if (this.removeAccents(componenteDir.long_name.toLowerCase()) === this.removeAccents(this.localidad.toLowerCase())) {
            array[contador] = array[contador] + 1;
        }
      } else if (componenteDir.types[0] === 'locality') {
          if (this.removeAccents(componenteDir.long_name.toLowerCase()) === this.removeAccents(this.municipio.toLowerCase())) {
            array[contador] = array[contador] + 3;
          } else  if (this.removeAccents(componenteDir.long_name.toLowerCase()) === this.removeAccents(this.localidad.toLowerCase())) {
            array[contador] = array[contador] + 2;
          } else  if (this.removeAccents(componenteDir.long_name.toLowerCase()) === this.removeAccents(this.estado.toLowerCase())) {
            array[contador] = array[contador] + 1;
          }
        } else if (componenteDir.types[0] === 'administrative_area_level_2') {
          if (this.removeAccents(componenteDir.long_name.toLowerCase()) === this.removeAccents(this.municipio.toLowerCase())) {
            array[contador] = array[contador] + 1;
          }
        } else if (componenteDir.types[0] === 'administrative_area_level_1') {
            if (this.removeAccents(componenteDir.long_name.toLowerCase()) === this.removeAccents(this.estado.toLowerCase())) {
              array[contador] = array[contador] + 1;
            }
          } else if (componenteDir.types[0] === 'route') {
            if (this.removeAccents(componenteDir.long_name.toLowerCase()) === this.removeAccents(this.calle.toLowerCase())) {
              array[contador] = array[contador] + 1;
            }
          }
      });
      contador++;
  });
  const valorMaximo = Math.max.apply(null, array);
  return { valor: valorMaximo, ubicacion: array.indexOf(valorMaximo)};

 }

 removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

  geocodeAddressUpdateCoor(latLng, calle, localidad, municipio, estado) {
    this.localidad = localidad;
    this.municipio = municipio;
    this.calle = calle;
    this.estado = estado;
    this.geocodeLatLngUpdate(this.geocoder, latLng);
  }
  geocodeLatLngUpdate(geocoder, latlngIn) {

    if (geocoder === undefined && geocoder == null) {
      return;
    }

    // OBTENER COORDENADAS
    const latlng = latlngIn;
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.map.setCenter(latlng);
          const nuevoMarcador: Lugar = {
            nombre: 'Ubicación domicilio',
            lat: latlng.lat,
            lng: latlng.lng,
            id: new Date().toISOString()
          };
          this.coordenadas = nuevoMarcador;
          this.marcadorAux = nuevoMarcador;
          this.agregarMarcador(nuevoMarcador);
        } else {
          console.log('No results found');
        }
      }
    });
  }

  geocodeLatLng(geocoder, map, infowindow) {
    // OBTENER COORDENADAS
    const latlng = { lat: this.marcador.getPosition().lat(), lng: this.marcador.getPosition().lng() };
    geocoder.geocode({ 'location': latlng }, (results, status) => {
      if (status === 'OK') {
        console.log(results);
        const ubicacion = this.validarDireccionGeocoder(results);
        if (results[0]) {

      if (ubicacion.valor < 3) {
        this.coordenadas = this.marcadorAux;
        this.agregarMarcador(this.coordenadas);
        const latLng = new google.maps.LatLng(this.marcadorAux.lat, this.marcadorAux.lng);
        this.map.setCenter(latLng);
        this.map.setZoom(18);
        this.openDialogMsg('La ubicación no corresponde a la direccion capturada. Revisa y corrige tus datos')
      } else {
        this.marcadorAux = this.coordenadas;
      }
    }

    }

    });
  }

  openDialogMsg(msg: string): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });

  }

}
