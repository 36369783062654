<img class="logoBan01" src="../../../../assets/img/logo_Ban_01.svg">
<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center" id="aprobadoAdE"></div>
<div class="container peque">
    <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
        
        <div *ngIf="validaTipoAutorizado()">

            <div class="resumenGral">
                <span class="AdE" *ngIf="producto===1">Adelanto <br> <strong>de Efectivo</strong></span>
                <span class="Ps" *ngIf="producto===2">Crédito<br> <strong>Personal</strong></span>
                <img class="all" alt="" src="../../../../../assets/img/resumen_felicidades.png" />
            </div>
            <h4 class=" name text-center">¡Muchas felicidades {{nombreEmpleado}}!</h4>
            <p class="sub"><strong>Te tenemos una gran noticia</strong></p>
            <p class="text-center">Ya fue aprobado tu {{txtProducto}} de:</p>
            <h5 class="cantidad">{{(adelanto.montoSolicitado|conversor)}}</h5>
            <div class="row">
                <table class="table table-striped two col-8">
                      <tbody>
                            <tr>
                                  <td class="col-8"><strong>{{producto===1?'Monto solicitado del adelanto:':'Monto del Credito:'}}</strong>
                                  </td>
                                  <td class="col-4 text-right"><strong>{{producto===1?(adelanto.montoSolicitado|conversor):(adelanto.montoSolicitado|conversor)}}</strong>
                                  </td>
                            </tr>
                            <tr>
                                  <td class="col-8">{{producto===1?'Comisión por disposición 1%:':'*Tasa fija anual:'}}</td>
                                  <td class="col-4 text-right">{{producto===1?(adelanto.montoComision|conversor):(adelanto.montoComision|conversor)}}</td>
                            </tr>
                            <tr *ngIf="producto===1">
                                  <td class="col-8">IVA de la comisión*:</td>
                                  <td class="col-4 text-right">{{(adelanto.montoIvaComision|conversor)}}</td>
                            </tr>
                            <tr>
                                  <td class="col-8"><strong>{{producto===1?'Monto total a pagar del Adelanto:':'Fecha del primer pago:'}}</strong></td>
                                  <td class="col-4 text-right"><strong>{{producto===1?(adelanto.montoCobro|conversor):(adelanto.montoCobro|conversor)}}</strong></td>
                            </tr>
                      </tbody>
                </table>
          </div>
            <table class="table fine">
                <tbody>
                    <tr>
                        <td class="text-right">
                            <p class="text-left">El monto de tu {{txtProducto}} se depositó en la cuenta donde recibes tu nómina de Banco Azteca.
                            </p>
                            <p class="text-left" *ngIf="producto===1">Recuerda que a partir de tu siguiente fecha de pago se realizará el
                                cargo de forma automática en dicha cuenta.</p>
                            <p class="text-left" *ngIf="producto===2">Recuerda que a partir de tu siguiente fecha de pago se realizará el
                                cargo de forma automática en dicha cuenta.</p>
                            <p class="text-left" *ngIf="producto===2">Para mayor información puedes consultar tu tabla
                                de amortización.</p>
                            <p class="cat" *ngIf="producto===2">CAT (Costo Anual Total): 14%</p>
                            <p class="cat" *ngIf="producto===1">CAT (Costo Anual Total): {{adelanto.cat}}%</p>
                            <p class="cat" *ngIf="producto===1">*Todas las comisiones se cobrarán más el Impuesto al Valor Agregado (IVA).</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="tipoRespuesta===2">
            <div class="resumenGral">
                <span class="AdE" *ngIf="producto===1">Adelanto de<br>Efectivo</span>
                <span class="Ps" *ngIf="producto===2">Préstamo<br>Socio</span>
                <img class="all" alt="" src="../../../../../assets/img/resumen_digitaliza.png" />
            </div>
            <br>
            <br>
            <p class="text-center">Estimado colaborador, al validar la documentación que nos <br> proporcionaste detectamos algunas inconsistencias:</p>
            <p class="text-center error-firmaazteca"><strong>Por favor vuelve a cargar tu comprobante de domicilio <br> y/o identificación oficial vigentes</strong></p>
            <h4 class=" name text-center">¡No te preocupes!</h4>
            <br>
            <p class="text-center">Revisa las especificaciones que deben cumplir éstos documentos en la <br> seccion de alta de documentos. Asegúrate que las imagenes sean claras y legibles</p>
            <p class="hrs"><strong>Hasta que nos envies nuevamente tus documentos podremos<br>continuar con el trámite de tu solicitud.</strong></p>
            <br>
            <button class="btn all salir" (click)="routeFunction('/altadocumentos')"
                routerLinkActive="router-link-active" *ngIf="tipoRespuesta===2">
                Digitalizar documentos</button>

        </div>
        <div *ngIf="validaTipoAutorizado()===false && tipoRespuesta!=2">
            <div class="resumenGral">
                <span class="AdE" *ngIf="producto===1">Adelanto de<br>Efectivo</span>
                <span class="Ps" *ngIf="producto===2">Préstamo<br>Socio</span>
                <img class="all" alt="" src="../../../../../assets/img/resumen_rechazado.png" />
            </div>
            <br><br><br>
            <div class="offset-md-3 col-md-6" *ngFor="let mensaje of mensajes;let i=index">
                <h5 [ngClass]=mensaje.clases>{{mensaje.mensaje}}</h5>
            </div>
            <br>
            <br>
            <button class="btn all salir" (click)="continuarSinCredito()"
            routerLinkActive="router-link-active" *ngIf="validaTipoAutorizado()===false && tipoRespuesta!=2">
            Salir</button>
        </div>
        <br><br>
        <p class="contactanos">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
        <p class="telefono"><img src="../../../../../assets/img/tel.svg" alt="teléfono" /> 55 7099 1199 – Opción 5</p>
        <a class="correo" href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a>
    </div>
    <div class="btns_centrados">
        <button name="btnAdelanto" class="btn all salir" (click)="routeFunction('/home')"
            routerLinkActive="router-link-active" *ngIf="validaTipoAutorizado()">Ver mi adelanto de
            efectivo</button>
    </div>
    <br><br><br><br><br>
</div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
    
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  

    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  

    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  

        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  

    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>  
