import { NgModule } from '@angular/core';

import { DatosSocioComponent } from './1.datosSocio/datos.socio.component';
import { DatosSocioRoutingModule } from './1.datosSocio/datos-routing.module';
import { AltaDocumentosRoutingModule } from './2.altaDocumentos/altaDocumentos-routing.module';
import { AltaDocumentosComponent } from './2.altaDocumentos/altaDocumentos.component';

import { ConfiguradorRoutingModule } from './3.configurador/configurador-routing.module';
import { ConfiguradorComponent } from './3.configurador/configurador.component';

import { ReferenciasFamiliaresComponent } from './4.referenciasFamiliares/referencias.familiares.component';
import { ReferenciasFamiliaresModule } from './4.referenciasFamiliares/referencias-routing.module';
import { AutorizaSolicitudComponent } from './5.autorizaSolicitud/autoriza.solicitud.component';
import { AutorizaSolicitudFAMComponent } from './5.autorizaSolicitudFAM/autorizasolicitudfam.component';
import { AutorizaSolicitudFAMRoutingModule } from './5.autorizaSolicitudFAM/autorizasolicitudfam-routing.module';
import { AutorizaSolicitudRoutingModule } from './5.autorizaSolicitud/autorizasolicitud-routing.module';
import { ResumenRoutingModule } from './6.resumen/resumen-routing.module';
import { ResumenComponent } from './6.resumen/resumen.component';
import { AprobadoComponent } from './7.aprobado/aprobado.component';
import { AprobadoRoutingModule } from './7.aprobado/aprobado-routing.module';
import { PerfilsocioComponent } from './PerfilSocio/perfilsocio.component';
import { PerfilsocioModule } from './PerfilSocio/perfilsocio-routing.module';
import { AngularMaterialModule } from 'src/app/shared/layout/angularMaterial/angularMaterial.module';
import { SharedModule } from '../../../shared';
import { ngfModule } from 'angular-file';
import { CreditoModule } from '../credito.module';
import { ValidarDirective } from '../../../shared/components/directiva/validar.directive';
import { NgxMaskModule } from 'ngx-mask';
import { DialogModule } from '../../../shared/components/modales/modales.module';
import { MapaModule } from '../../../shared/components/mapaGoogle/mapa.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  imports: [
    MapaModule,
    DialogModule,
    DatosSocioRoutingModule,
    ConfiguradorRoutingModule,
    AltaDocumentosRoutingModule,
    AngularMaterialModule,
    SharedModule,
    DialogModule,
    AutorizaSolicitudRoutingModule,
    AutorizaSolicitudFAMRoutingModule,
    ResumenRoutingModule,
    NgxSliderModule,
    ReferenciasFamiliaresModule,
    AprobadoRoutingModule,
    NgxExtendedPdfViewerModule,
    PerfilsocioModule,
    ngfModule,
    CreditoModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    DatosSocioComponent,
    ConfiguradorComponent,
    AltaDocumentosComponent,
    AutorizaSolicitudComponent,
    AutorizaSolicitudFAMComponent,
    ReferenciasFamiliaresComponent,
    ResumenComponent,
    AprobadoComponent,
    PerfilsocioComponent,
    ValidarDirective
  ],
  exports: [
    ResumenComponent
  ]
})
export class PaginasComunesModule {}
