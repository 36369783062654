import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogDataCan {
  message: string;
  opciones: boolean;
}
@Component({
  selector: 'app-dialog-overview-msg2',
  template: ` <div class="modalSmall">
  <p>{{data.message}}<strong>Actualizar documentos!</strong></p>
<div class="btns_centrados">
  <button type="button" class="btn cancelar" (click)="cerrarModal()">Actualizar mas tarde</button>
  <button type="submit" class="btn all" (click)="cancelar()">Actualizar documentos</button>
</div>
<div class="footer">&nbsp;</div>
</div>`,
  styleUrls: ['dialog-overview-msg1.css']
})

export class DialogCondicionadoComponent {
  cancelaciones = [];
  opciones: boolean;
  datosCancelaciones: any;
  numEmpleado: string;
  producto: string;
  constructor(
    public dialogRef: MatDialogRef<DialogCondicionadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCan) {
      this.dialogRef.disableClose = true;
    }

  cerrarModal(): void {
    this.dialogRef.close(false);
  }

  cancelar() {
    this.dialogRef.close(true);
  }


}
