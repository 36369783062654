<h2 class="title">5. Consulta la documentación de tu solicitud de crédito.</h2>
<p *ngIf="mostrarAdE" class="subtitle">Verifica tu contrato.</p>
<!--<p *ngIf="mostrarPs" class="subtitle">Selecciona el documento que desees verificar:</p>-->
<br><br>

<mat-tab-group (selectedTabChange)="activateTab($event.index)">
  <mat-tab label='Contrato'>
    <ng-template matTabContent>
      <div style="height: 800px; overflow-y: auto;">
        <ngx-extended-pdf-viewer
          *ngIf="visible[0]"
          [src]="contratoPdfADE"
          useBrowserLocale="true"
          height="70vh">
        </ngx-extended-pdf-viewer>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
        Aviso <br> de Privacidad
    </ng-template>
    <ng-template matTabContent>
      <div style="height: 800px; overflow-y: auto;">
        <ngx-extended-pdf-viewer
          *ngIf="visible[1]"
          [src]="avisoPrivacidad"
          useBrowserLocale="true"
          height="70vh">
        </ngx-extended-pdf-viewer>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>

<div class="col pad btns_foot">
  <button class="btn atras" (click)="routeFunction('/configurador')">Atrás</button>
  <button class="btn cancelar" (click)="openDialogCancelacionesMensaje()">Cancelar</button>
  <button class="btn all" (click)="guardarConsentimientos()" routerLinkActive="router-link-active">Continuar</button>
</div>

<script>
  if (self === top) {
    document.documentElement.style.display = 'block';
  } else {
    redireccionAlSitio();
  }

  function redireccionAlSitio() {
    try {
      var surl = self;
      if (validateURL(surl))
        top.location.href = surl;
      else {
        throw new InvalidURLException();
      }
    } catch (e) {
      if (e instanceof InvalidURLException)
        alert(e.message);
    }
  }

  function InvalidURLException() {
    this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";
    this.toString = function () {
      return this.message
    };
  }

  function validateURL(surl) {
    var url = parseURL(surl);
    var urlHostname = url.hostname.trim();

    if (urlHostname == '') {
      return true;
    } else {
      if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {
        return true;
      } else {
        return false;
      }
    }
  }

  function parseURL(url) {
    var a = document.createElement('a');
    a.href = url;
    return {
      source: url,
      protocol: a.protocol.replace(':', ''),
      hostname: a.hostname,
      host: a.host,
      port: a.port,
      query: a.search,
      params: (function () {
        var ret = {},
          seg = a.search.replace(/^\?/, '').split('&'),
          len = seg.length, i = 0, s;
        for (; i < len; i++) {
          if (!seg[i]) { continue; }
          s = seg[i].split('=');
          ret[s[0]] = s[1];
        }
        return ret;
      })(),
      file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
      hash: a.hash.replace('#', ''),
      path: a.pathname.replace(/^([^\/])/, '/$1'),
      relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
      segments: a.pathname.replace(/^\//, '').split('/')
    };
  }
</script>