import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HeaderService } from '../header.options';
import { CotizadorPrestamoReq} from '../../../models/credito/3.configurador/cotizadorReq.prestamo';
import { CotizadorPrestamoPost} from '../../../models/credito/3.configurador/cotizadorPost.model';
import { catchError } from 'rxjs/operators';
import { ValidacionErrors } from '../../utileria/validacion.error';
import { DialogOverviewMsg} from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { MatDialog } from '@angular/material/dialog';
@Injectable({ providedIn: 'root' })
export class ApiPrestamoCotizadorService {

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private headers: HeaderService,
    private validacionError: ValidacionErrors

  ) {
   }

  // Metodo para obtener los montos del configurador(MontoSolicitado, comision,montoAdelanto, fechaCobro)
  // Recibe parametros numeroEmpleado y monto para calcular
  getObtenerCotizacionPrestamo(cotizadorPrestamoReq: CotizadorPrestamoReq): Observable<any> {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', '' + cotizadorPrestamoReq.numeroEmpleado);
    parametros.set('montoSolicitado', '' + cotizadorPrestamoReq.montoSolicitado);
    parametros.set('capacidadPago', '' + cotizadorPrestamoReq.capacidadPago);
    parametros.set('plazo', '' + cotizadorPrestamoReq.plazo);
    parametros.set('idTipoNomina', '' + cotizadorPrestamoReq.idTipoNomina);

   return this.httpClient.get<any>(
      environment.api_url_prestamos_cotizadores + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }).pipe(
        catchError( e => {
          if (e.status >= 400 && e.status < 500) {
            this.dialog.closeAll();
            this.openDialogMsg(this.validacionError.getError(e));
        }
    throw e;
        })
      );

  }


  // Metodo para guardar la solicitud con(MontoSolicitado, comision,montoAdelanto, fechaCobro)
  // Recibe parametros numeroEmpleado y monto para calcular y guardar
   postGuardarCotizacionPrestamo(cotizadorPost: CotizadorPrestamoPost): Observable<any> {

    return this.httpClient.post<any>(
       environment.api_url_prestamos_cotizaciones, cotizadorPost,
       { headers: this.headers.postHeaderOption(false) }).pipe(
        catchError( e => {
          this.dialog.closeAll();
          this.openDialogMsg(this.validacionError.getError(e));
          throw e;
        })
      );
   }

       // Metodo para obtener los montos del configurador(MontoSolicitado, comision,montoAdelanto, fechaCobro)
  // Recibe parametros numeroEmpleado y monto para calcular
  getObtenerPlazos(numeroEmpleado: string): Observable<any> {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numeroEmpleado);

   return this.httpClient.get<any>(
      environment.api_url_prestamos_plazos + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }).pipe(
        catchError( e => {
          this.dialog.closeAll();
          this.openDialogMsg(this.validacionError.getError(e));
          throw e;
        })
      );

  }


   openDialogMsg(msg: string): void {
    this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true,
        autoFocus: false
      }
    });
  }
}