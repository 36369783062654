import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';

import { UserModel } from '../models/user.model';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenModel } from '../models/token.model';
import * as CryptoJS from 'crypto-js';


@Injectable({ providedIn: 'root' })
export class UserService {
  private currentUserSubject = new BehaviorSubject<UserModel>({} as UserModel);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private httpClient: HttpClient,
    private router: Router
  ) { }

  iniciarSesion() {
    this.router.navigate(['/externalRedirect', { externalUrl: environment.oktaLogin }], {
      skipLocationChange: true,
    });
    
  }

  public validarSesionSAML(): Observable<any>{
    var isValid=false;
      return this.httpClient.get(environment.oktaValidaSesion);
  
  }
  
  public cerrarSesionSAML(): Observable<any>{
    var isValid=false;
      return this.httpClient.get(environment.oktaLogout);
  
  }
  public obtenerTokenApigee(): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(environment.api_client_id + ':' + environment.api_client_secret)

    });
    const parametros = new URLSearchParams();
        parametros.set('grant_type', 'client_credentials');
    return this.httpClient.post(environment.api_url_token, parametros.toString(), {headers: headers});
  }

  setNumeroEmpleado(numeroEmpleado:string) {
     sessionStorage.setItem('numeroEmpleado', CryptoJS.AES.encrypt(numeroEmpleado.trim(), environment.contra_crypto).toString() );
  }

  getNumeroEmpleado(): string{
    if(sessionStorage.getItem('numeroEmpleado'))
    return CryptoJS.AES.decrypt(sessionStorage.getItem('numeroEmpleado'), environment.contra_crypto).toString(CryptoJS.enc.Utf8);
    return null;
  }

  public recoverToken() {
    return this.jwtService.getToken();
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  setAuth(user: UserModel) {
    // dependiendo de lo que retornen los servicios de inicio de sesion
    // obtener el User y el token de sesion
    this.jwtService.saveToken(user.token);

    this.currentUserSubject.next(user);

    this.isAuthenticatedSubject.next(true);
  }

  cierreSesion() {
    // Remove JWT from sessionStorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as UserModel);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Remove other data
    this.jwtService.destroyData();
  }
  
  public obtenerTokenApigeeOnpremise(): Observable<any> {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(environment.api_client_id_onpremise + ':' + environment.api_client_secret_onpremise)

    });
    const parametros = new URLSearchParams();
        parametros.set('grant_type', 'client_credentials');
    return this.httpClient.post(environment.api_url_token_onpremise, parametros.toString(), {headers: headers});
  }
}