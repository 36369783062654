import { TipoTelefono } from '../../services/utileria/utileria.enum';

export class TelefonoModel {
    idTelefono: number;
    idTipo: number;
    tipo: string;
    telefono: string;
    extension: string;

    constructor(idTelefono: number, idTipo: number, tipo: string,
        telefono: string, extension: string) {
        this.idTelefono = idTelefono;
        this.idTipo = idTipo;
        this.tipo = tipo != null || tipo !== ''  ? tipo : TipoTelefono.TIPO_MOVIL;
        this.telefono = telefono;
        this.extension = extension != null ? extension : '';
    }
}
