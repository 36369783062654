import { Component, ViewChild,  Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AsideComponent} from '../aside/aside.component';

@Component({
  selector: 'app-body',
  template: `
  <div class="container">
    <app-spinner></app-spinner>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12" #head id="head"></div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 pad" #myaside id="myaside">
        <app-aside></app-aside>
      </div>
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12" #body id="body">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="fondo_circulo"></div>
  </div>
  `,
  styleUrls: ['./body.component.scss']
})

export class BodyComponent {

  name = 'home';
  mostrar: true;

  @ViewChild(AsideComponent, { static: true }) asideComponent: AsideComponent;
  @ViewChild('myaside', { static: true }) myAside: ElementRef;
  @ViewChild('body', { static: true }) myBody: ElementRef;
  @ViewChild('head', { static: true }) myHead: ElementRef;
  constructor(private router: Router, private renderer: Renderer2) { }

  ocultarBody( tipoEstilo: string, idRemplazo: string, idRemplazoHead: string) {
    this.renderer.setStyle(this.myAside.nativeElement, 'display', tipoEstilo);
    this.renderer.removeAttribute(this.myBody.nativeElement, 'id');
    this.renderer.setAttribute(this.myBody.nativeElement, 'id', idRemplazo);
    this.renderer.removeAttribute(this.myHead.nativeElement, 'id');
    this.renderer.setAttribute(this.myHead.nativeElement, 'id', idRemplazoHead);
  }

  ocultarClaseModalOpen(): void {
    this.renderer.removeClass(this.myBody.nativeElement, 'modal-open');
  }

  scrollTopZero(): void {
    this.renderer.setStyle(this.myBody.nativeElement, 'scrollTop', 0);
  }

  setImagenAside(imagen: String) {
    this.asideComponent.cambiarImagenAside(imagen);
  }

  setProgressAside(produto_id) {
    this.asideComponent.setProgress(produto_id);
  }

  setStyleAside(producto_id) {
    this.asideComponent.setBkgStyles(producto_id);
  }

}