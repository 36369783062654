<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/folleto_credito_auto.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/infografia_credito_auto.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
   <!--<a class="video" (click)="mostrarVideo()"> Video</a>--> 
    <a class="faqs" href="../../../../../../assets/docs/home/faqs_credito_auto.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">Adquiere el auto que deseas con Crédito Auto GS</h1>
<p class="textoverdeTor"><strong>¿Qué es?</strong></p>
<p>Crédito con el cual podrás adquirir un auto nuevo o seminuevo, <strong>en agencias, lotes, plataformas o con algún particular</strong><sup>1</sup> </p>
<p class="textoverdeTor"><strong>¿Para quién es?</strong></p>
<p>Colaboradores que reciban la invitación a través de la App de Banco Azteca y con una antigüedad laboral mínima de 3 años</p>
<p class="textoverdeTor"><strong>¿Qué Beneﬁcios exclusivos tiene?</strong></p>
<ul class="contenido">
    <li>El proceso es inmediato y fácil</li>
    <li>Podrás realizar prepagos o liquidar sin penalizaciones</li>
</ul>

<p class="textoverdeTor"><strong>Características</strong></p>
<ul class="contenido">
    <li><strong>Financiamos hasta 90%</strong> del valor del auto </li>
    <li>Indispensable dar <strong>el 10% de enganche</strong></li>
    <li>Participan autos nuevos, seminuevos, de agencias, del catálogo <strong>de socios comerciales<sup>1</sup> o compra entre particulares</strong></li>
    <li>Plazos 12, 24, 36 y 48 meses con su equivalencia en semanas o quincenas</li>
    <li>Pago con cargo directo a la cuenta de nómina (alineados a los días de pago)</li>
    <li>Seguro de auto y  sistema GPS<sup>2</sup></li>
</ul>

<div  class="container" >
    <div class="row">
        <div  >
           
            <img   style ="width: 40%;" src="../../../../assets/img/tabla_crediAuto.png" />
          </div>
    </div>
</div>

<p class="textoverdeTor"><strong>Requisitos</strong></p>
<ul class="contenido">
    <li>Antigüedad mínima de 3 años </li>
    <li>Línea de crédito activa</li>
    <li>Capacidad de pago disponible (30% del ingreso neto)</li>
    <li>Buen historial crediticio</li>
</ul>
<p class="textoverdeTor"><strong>Proceso entre particulares </strong></p>
<p>Si vas a utilizar la opción de compra entre particulares, tramita tu Constancia de Validación para iniciar tu proceso de compra**</p>
<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
        <a class="btn all inicio_sec button2" href="../../../../../../assets/docs/home/Master.pdf" style= "text-decoration: none;" target="_blank" rel="noopener noreferrer"> Master Fleet*</a>
    </div>    
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
    <a class="btn all inicio_sec button" href="../../../../../../assets/docs/home/trust.pdf" style= "text-decoration: none;" target="_blank" rel="noopener noreferrer" >Trust*</a>
        </div>

</div> 

<div class="subindices">
    <p><sup><strong>1</strong></sup> Habolt, Kavak, ClikAuto, El Trebol, Trevak</p>
    <p><sup><strong>2</strong></sup> El GPS y el seguro de daños son accesorios adicionales obligatorios, los cuales serán pagados
        por el Colaborador al momento de la contratación del crédito</p>
    <p>&nbsp;&nbsp;&nbsp;* Todos los logotipos, marcas y nombres comerciales mencionados son propiedad de sus respectivos titulares, sus derechos se encuentran protegidos por las leyes en materia de propiedad industrial e intelectual, Banco Azteca S.A. Institución de Banca Múltiple. Todos los derechos reservados 2023 </p>
    <p>&nbsp;&nbsp;&nbsp;** El trámite de la Constancia de Validación es responsabilidad del proveedor que la emite y del cliente que contrate sus servicios; Banco Azteca S.A. Institución de Banca Múltiple no tiene responsabilidad por los servicios prestados por el proveedor</p>

</div>
<br>

    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
          </div>
          <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
          </div>
    </div>
    <hr class="prodBottom">
    <p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
    <p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> <strong>55 7099 1199</strong> <br> Opción <strong>5</strong></p>
    <div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
        href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div>
    
        <div class="abrir-modal animacion fadeIn" *ngIf="modalVideo">
            <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div>
                        <button (click)="cerrarVideo()" type="button" class="close cmodal" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                 </button>
                    </div>
                        
                    <div class="embed-responsive embed-responsive-21by9">
                        <iframe title="" class="embed-responsive-item" src="../../../../../../assets/videos/VideoCrediAuto.mp4"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>


<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>
