import { Component, Host, Input } from '@angular/core';
import { HomeComponent } from '../../home.component';

@Component({
  selector: 'app-adelanto-home',
  templateUrl: './adelanto.home.html',
  styleUrls: ['./adelanto.home.scss']
})
export class AdelantoHomeComponent {

  @Input() activoli;

  parentHome: HomeComponent;
  modalVideo = false;

  constructor(
 parentHome: HomeComponent
  ) {
    this.parentHome = parentHome;
  }

  mostrarVideo() {
    this.modalVideo = true;
  }

  cerrarVideo() {
    this.modalVideo = false;
  }

  validacionAdE() {
    this.parentHome.validacionAvisoPrivacidad(1);
  }
}
