import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResumenComponent } from './resumen.component';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'resumen',
    component: ResumenComponent,
    canActivate: [AuthGuardService]  // <-- Implementar para manejar el acceso
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResumenRoutingModule {}