
import { TelefonoModel } from '../telefono.model';
import { DireccionModel } from '../direccion.model';

export class DatosSocioModel {

    numeroEmpleado: string;
    fechaIngreso: string;
    correoElectronico: string;
    telefonos: TelefonoModel[];
    direcciones: DireccionSocioModel[];

    constructor(numeroEmpleado: string, correoElectronico: string,
        telefonos: TelefonoModel[], direcciones: DireccionSocioModel[]) {
            this.numeroEmpleado = numeroEmpleado;
            this.correoElectronico = correoElectronico;
            this.telefonos = telefonos;
            this.direcciones = direcciones;
    }
}

export class DireccionSocioModel {
    idDireccion: number;
    calle: string;
    idColonia: number;
    colonia: string;
    idDelegacionMunicipio: number;
    delegacionMunicipio: string;
    idEstado: number;
    estado: string;
    codigoPostal: string;
    numExterior: string;
    numInterior: string;
    referenciaDomicilio: string;
    numTiendaCercana: string;
    latitud: string;
    longitud: string;
    calleAdelante: string;
    calleAtras: string;
    calleDerecha: string;
    calleIzquierda: string;
}

