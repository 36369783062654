import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { DetalleCodigoPostalModel } from '../../../models/credito/response/detalle.codigo.postal.model';
import { HeaderService } from '../header.options';
import { environment } from '../../../../../environments/environment';
import { ResponseModel } from 'src/app/core/models/credito/response/response.model';
import { catchError, map } from 'rxjs/operators';
import { mapaApi } from 'src/app/core/models/credito/api.zonificacion/mapaApi.model';
import { InformacionCodigoPostal } from 'src/app/core/models/credito/api.zonificacion/informacionCodigoPostal.model';



@Injectable({ providedIn: 'root' })
export class ApiZonificacionService {

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private headers: HeaderService
  ) { }

  getInformacionCodigoPostal(codigoPostal): Observable<InformacionCodigoPostal> {
    const parametros = new URLSearchParams();
    parametros.set('codigoPostal', codigoPostal);

    return this.httpClient.get<mapaApi>(
      environment.api_url_zonificacion + parametros.toString(),
      { headers: this.headers.getHeaderOptionOnpremise(false) }
    ).pipe(
      map(response => response.resultado),
        catchError(e => {
          throw e;
        })
      );

    // const promise = new Promise((resolve, reject) => {
    //   this.httpClient.get<ResponseModel>(
    //     environment.api_url_zonificacion + parametros.toString(),
    //     { headers: this.headers.getHeaderOption(false) }
    //   ).toPromise()
    //     .then(data => {
    //       console.log(data.detalleCP);
    //       resolve(data);
    //     }, error => {
    //       console.error(error);
    //       reject(error);
    //     }).catch();
    // });
    // return promise;
  }
}
