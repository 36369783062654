import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portal-guatemala',
  templateUrl: './portal-guatemala.component.html',
  styleUrls: ['./portal-guatemala.component.scss']
})
export class PortalGuatemalaComponent implements OnInit {
 pais: any;
  constructor() { }

  ngOnInit(): void {
  }

}
