import { Component, OnInit } from '@angular/core';
import { SpinnerService} from './spinner.service';

@Component({
  selector: 'app-spinner',
  template: `<div id="spinner-datossocio" class="spinner" hidden>
  <p>{{spinnerService.spinnerText}}</p>
  </div>`
})

export class SpinnerComponent implements OnInit {

  constructor(public spinnerService: SpinnerService) {}

  ngOnInit() {
    this.spinnerService.spinnerText = '';
  }
}
