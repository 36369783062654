<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/FOLLETO_CBF.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/INFOGRAFIA_CBF.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    
    <a class="faqs" href="../../../../../../assets/docs/home/Preguntas_frecuentes_CBF.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡Solicita YA! Desde tu App <img class = "imgWidthe" src="../../../../assets/img/baz.png" ></h1>
<p class="textoverdeTor"><strong>¿Qué es?</strong></p>
<p>Crédito inmediato, <strong>sin aval ni garantías</strong>, disponible a través de la <strong class="textoverdeTor">App </strong>   <img class = "imgWidthe" src="../../../../assets/img/baz.png" > las <strong>24h, los 365 días</strong></p>
<p class="textoverdeTor"><strong>¿Para quién es?</strong></p>
<p>Colaboradores con antigüedad mínima de <strong>2 años</strong></p>
<p class="textoverdeTor"><strong>¿Qué Beneficios exclusivos ofrece?</strong></p>
<ul class="contenido">
    <li>Disposición inmediata</li>
    <li>Reconocimiento facial para asegurarnos que tu pediste el crédito<sup>1</sup></li>
    <li>Tasa preferencial de acuerdo a tu antigüedad</li>
    <li>Pagos anticipados y liquidación sin penalización</li>
    
</ul>


<div  class="container" >
    <div class="row">
        <div  >
           
            <img   style ="width: 30%;" src="../../../../assets/img/tablaTasas.png" />
          </div>
    </div>
</div>


<p class="textoverdeTor"><strong>Características</strong></p>
<ul class="contenido">
    <li>Puedes solicitar desde $1,000 hasta $300,000 <sup>2</sup></li>
    <li>Plazos de 6, 12 y 18 meses o su equivalente en semanas y quincenas </li>
    <li>Solo podrás tener un crédito activo </li>
    
</ul>
<br/><br/>
<div class="subindices">

    <p><sup>1</sup> Actualiza tus biométricos en sucursal, presenta los siguientes documentos:</p>

    <ul>
        <ol style= "list-style-type: circle;">
            <li>INE Vigente</li>
            <li>Comprobante de domicilio (no mayor a tres meses)</li>
        </ol>
    </ul>

    <p><sup>2</sup> Sujeto a capacidad de pago</p>

</div>


<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
      </div>
      <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
      </div>
</div>
<hr class="prodBottom">
<p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
<p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> 55 7099 1199 <br> Opción 5</p>
<div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
    href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div><br><br><br>



<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>