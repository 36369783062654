import { HttpClient } from '@angular/common/http';
import { HeaderService } from '../header.options';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AutorizacionesModel } from './AutorizacionesModel';

@Injectable({ providedIn: 'root' })
export class ApiAutorizacionesService {
    constructor(
        private httpClient: HttpClient,
        private header: HeaderService
    ) {
    }

    postAutorizaciones(numeroEmpleado: string, token: number): Observable<any> {
        const autorization = new AutorizacionesModel();
        autorization.numeroEmpleado = numeroEmpleado;
        autorization.token = token;
        return this.httpClient.post(environment.api_url_autorizaciones, autorization,
            { headers: this.header.postHeaderOption(false) }).
            pipe(
                catchError(e => {
                    console.log(e);
                    throw e;
                })
            );
    }
}
