<p *ngIf="!showDocuments">Historial</p>
    <div *ngIf="!showDocuments">
        <table class="table_creditos" *ngFor="let data of ELEMENT_DATA_ADELANTO; let i = index;">
            <tr>
                <td class="verde">{{HEADER_DATA_ADELANTO[0]}}</td>
                <td class="strong">{{data.idAdelanto}}</td>
                <td>{{HEADER_DATA_ADELANTO[1]}}</td>
                <td class="mediumStrong">{{data.montoSolicitado | currency:'MXN':'symbol-narrow' }}</td>
            </tr>
            <tr>
                <td>{{HEADER_DATA_ADELANTO[2]}}</td>
                <td class="mediumStrong"> {{ data.fechaSolicitud | FechaPipe}}</td>
                <td>{{HEADER_DATA_ADELANTO[3]}}</td>
                <td class="mediumStrong">{{data.montoComision | currency:'MXN':'symbol-narrow' }}</td>
            </tr>
            <tr>
                <!--<td>{{HEADER_DATA_ADELANTO[4]}}</td>
                <td class="mediumStrong">{{data.fechaCobro | FechaPipe}}</td> -->
                <td></td>
                <td></td>
                <td>{{HEADER_DATA_ADELANTO[5]}}</td>
                <td class="mediumStrong">{{data.montoIvaComision | currency:'MXN':'symbol-narrow' }}</td>
            </tr>
            <tr>
                <td>{{HEADER_DATA_ADELANTO[6]}}</td>
                <td></td>
                <td>{{HEADER_DATA_ADELANTO[7]}}</td>
                <td class="mediumStrong">{{data.montoCobro | currency:'MXN':'symbol-narrow'}}</td>
            </tr>
            <tr>
                <td class="legalDocs">
                    <a (click)="mostrarDocumentos(1, data.idAdelanto)">Ver Documentos <br> Legales > </a>
                </td>
            </tr>
            <tr>
                <td colspan="4" class="cat">
                    CAT (Costo Anual Total): {{data.cat}}% <br>
                    *Todas la comisiones se cobrarán más el impuesto al Valor Agregado (IVA)
                </td>
            </tr>
    </table>
</div>
<div *ngIf="!showDocuments">
    <table class="table_creditos" *ngFor="let data of ELEMENT_DATA_PRESTAMO; let i = index">
        <tr>
            <td class="verde">{{HEADER_DATA_PRESTAMO[0]}}</td>
            <td class="strong">{{data.folio}}</td>
            <td>{{HEADER_DATA_PRESTAMO[1]}}</td>
            <td class="mediumStrong">{{data.montoSolicitado | currency:'MXN':'symbol-narrow' }}</td>
        </tr>
        <tr>
            <td>{{HEADER_DATA_PRESTAMO[2]}}</td>
            <td class="mediumStrong">{{data.fechaSolicitud  }}</td>
            <td>{{HEADER_DATA_PRESTAMO[3]}}</td>
            <td class="mediumStrong">{{data.plazo}}</td>
        </tr>
        <tr>
            <td>{{HEADER_DATA_PRESTAMO[4]}}</td>
            <td class="mediumStrong">{{data.tasaFija}}</td>
            <td>{{HEADER_DATA_PRESTAMO[5]}}</td>
            <td class="mediumStrong">{{data.siguientePago  }}</td>
        </tr>
        <tr>
            <td colspan="3" class="legalDocs">
                <a (click)="mostrarDocumentos(2)">Ver Documentos Legales ></a>
            </td>
            <td colspan="1" class="cat">CAT (Costo Anual Total): 14%</td>
        </tr>
    </table>
</div>

<mat-tab-group (selectedTabChange)="activateTab($event.index)" *ngIf="showDocuments">
    <mat-tab label='Contrato'>
      <ng-template matTabContent>
        <div style="height: 800px; overflow-y: auto;">
            <ngx-extended-pdf-viewer
              *ngIf="visible[0]"
              [src]="contratoPdfADE"
              useBrowserLocale="true"
              height="70vh"
              delayFirstView="1000">
            </ngx-extended-pdf-viewer>
          </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
          Aviso <br> de Privacidad
      </ng-template>
      <ng-template matTabContent>
        <div style="height: 800px; overflow-y: auto;">
            <ngx-extended-pdf-viewer
              *ngIf="visible[1]"
              [src]="avisoPrivacidad"
              useBrowserLocale="true"
              height="70vh"
              delayFirstView="1000">
            </ngx-extended-pdf-viewer>
          </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

<div class="col pad btns_foot">
    <button type="button" (click)="exitFunction()" class="btn all">Salir</button>
</div>



<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>