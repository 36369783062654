<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/FOLLETO_GUAT.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/GUIA_GUAT.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    <!--<a class="video" (click)="mostrarVideo()"> Video</a> -->
    <a class="faqs" href="../../../../../../assets/docs/home/FAQS_GUAT.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡Tu lealtad te recompensa!</h1>
<p class="textoverdeTor"><strong>¿Qué es?</strong></p>
<p> Un Beneficio con el cual podrás <strong>comprar productos de las líneas hogar, conectividad</strong> y<strong> movilidad</strong> de contado o a crédito dentro de tiendas <strong>Elektra</strong></p>
<p class="textoverdeTor"><strong>¿Para quién es?</strong></p>
<p>Para todos los Colaboradores de <strong>Grupo Salinas Guatemala</strong></p>
<p class="textoverdeTor"><strong>¿Qué Beneficio obtengo?</strong></p>
<p>La posibilidad de adquirir <strong>miles de productos </strong>dentro de tiendas <strong>Elektra</strong>, con <strong>descuento</strong> de acuerdo a tu <strong>antigüedad</strong> sobre un <strong>precio exclusivo para ti </strong>,además podrás adquirir la <strong>Garantía Extendida Milenia</strong><sup>1</sup>, con un costo promocional,<strong> entre más antigüedad menos pagas</strong> </p>

<p>Consulta la siguiente tabla</p>

<div  class="container" >
    <div class="row">
        <div  >  
            <img   class = "imgWidth" src="../../../../assets/img/descuentoEKT.jpg" />
          </div>
    </div>
</div>


<p class="textoverdeTor"><strong>Características</strong></p>
<ul class="contenido">
    <li>Monto máximo de compra<sup>2</sup> es el equivalente hasta 3 veces la cantidad de tu sueldo neto mensual con un límite de <strong>Q11,000</strong></li>
    <li>El precio especial es variable con relación a tu antigüedad y a la familia del producto, a mayor antigüedad más descuento</li>
    <li>Solo aplica para compras en tiendas físicas <strong>Elektra</strong></li>
    <li>Productos Apple y consolas de videojuegos podrán adquirirse de contado y a crédito <strong>sin descuento</strong> por antigüedad</li>
    <p><strong>Para compras de Contado:</strong></p>
    <li>Podrás utilizar todo tu <strong>monto máximo de compra</strong>, sin embargo, si lo usas completo no tendrás saldo<sup>3</sup> para comprar a crédito</li>
    <p><strong>Para compras a Crédito:</strong> </p>
    <li>Podrás comprar<sup>4</sup>, máximo el equivalente a un mes de tu salario. Si lo usas todo y no rebasas el <strong>monto máximo de compra</strong> tendrás disponible el remanente para compras de contado
    </li>
    <li> Plazos de <strong>4 a 12 meses</strong> con cargo directo a tu nómina de Banco Azteca</li>
    <li>Tasa anual del <strong>24%</strong></li>
</ul>

<p class="contactanos text-center">Revisa tu monto disponible en Portal Recursos Humanos > Administración RH > Venta Empleados</p>


    <div class="subindices">
        <p><sup><strong>1</strong></sup>No aplica para motocicletas</p>
        <p><sup><strong>2</strong></sup>El límite puede variar con respecto a tu percepción salarial</p>
        <p><sup><strong>3</strong></sup>El límite disponible volverá a ser vigente, una vez concluido un año de tu primera compra de contado</p>
        <p><sup><strong>4</strong></sup>La línea de crédito disponible volverá a ser vigente al concluir el pago total de tu compra</p>
    </div>


    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
          </div>
          <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
          </div>
    </div>

    <p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
    <p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> <strong>2382-5911</strong></p>
    <div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
        href="mailto:atencionalcliente@elektra.com.gt">atencionalcliente@elektra.com.gt</a></div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>