
export enum UtileriaEnum {

    RECURSO_NO_ENCONTRADO = 'Recurso no encontrado, favor contactar al administrador.',
    MENSAJE_CAMPOS_INCOMPLETO = 'Verifica que todos los campos hayan sido capturados correctamente y de forma completa.',
    ERROR_FATAL = 'Error inesperado, servicio no disponible'
}

export enum TipoTelefono {
    TIPO_MOVIL = 'Móvil',
    TIPO_CASA = 'Casa',
    TIPO_OFICINA = 'Oficina'
}

export enum EstatusNotificaciones {
    ESTATUS_ACTIVO = 1,
    ESTATUS_LEIDO = 2,
    ESTATUS_ELIMINADO = 4
}

export enum EstatusConsentimiento {
    ESTATUS_NO_APROBADO = 0,
    ESTATUS_APROBADO = 1
}

export enum TipoDocumento {
    IDENTIFICACION_OFICIAL = 1,
    COMPROBANTE_DE_DOMICILIO = 2,
    CONTRATO_DE_PRESTAMO_SOCIO = 5,
    PAGARE = 6,
    AUTORIZACION_DE_CONSULTA_A_BURO = 16,
    SEGURO_DE_VIDA = 205,
    AVISO_DE_PRIVACIDAD = 392,
    TABLA_DE_AMORTIZACIÓN = 471,
    CONTRATO_DE_ADE = 498,
    CARTA_DE_ACEPTACION_DE_ADEUDO = 685,
}

export enum TipoProducto {
    ADELANTO_EFECTIVO = 1,
    PRESTAMO = 2
}

export enum ListaUrisValidas {
    OWN_SITE = 1
}

export enum Intentos {
    MAX_INTENTOS = 3
}

export enum OpcionesIntentos {
    GENERA_SOLICITUD = 1,
    CAPACIDAD_PAGO = 2,
    COTIZACIONES_GET = 3,
    COTIZACIONES_POST = 4
}

export enum OpcionesIntentosAltaDocumentos {
    GUARDAR_DOCUMENTOS = 1,
    VALIDA_MODAL = 2,
    ABRIR_MODAL = 3,
    GUARDAR_CONSENTIMIENTOS = 4
}

export enum Paises {
    MEXICO_ID = 1
}
