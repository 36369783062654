<div class="bannerAdelanto">
    <div class="acceso">
        <div [ngClass]="{'right':true}">
            <button id="btnSolicitud" type="button" name="btnSolicitalo"
                class="btn all inicio_sec" (click)="validacionAdE()">Ingresa aquí</button>

        </div>
    </div>
    </div>
    <div class="complementos">
        <a class="folleto" href="../../../../../../assets/docs/home/FolletoAdE.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
        <a class="guia_rapida" href="../../../../../../assets/docs/home/GuiaAdE.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
        <!--<a class="video" (click)="mostrarVideo()"> Video</a>-->
        <a class="faqs" href="../../../../../../assets/docs/home/PreguntasAdE.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
    </div>
    <h1 class="titleHome">Solicitar tu Adelanto de Efectivo, ¡Ahora es más fácil y rápido!</h1>
    <p class="textoverde"><strong>¿Qué es?</strong></p>
    <p><strong>Disposición en efectivo</strong> que va desde <strong>$500 </strong>hasta el <strong>30%</strong> de tu siguiente pago salarial<sup>1</sup></p>
    <p class="textoverde"><strong>¿Para quién es?</strong></p>
    <p>Colaboradores con mínimo <strong>6 meses </strong>de antigüedad que reciban nómina en su cuenta de <strong>Banco Azteca</strong> <sup>2</sup></p>
    <p class="textoverde"><strong>¿Qué beneficios exclusivos te ofrece?</strong></p>
    <ul class="contenido">
        <li>Comisión Preferencial por disposición del <strong>1 % + IVA</strong> (sobre monto dispuesto, por evento)</li>
        <li>Disposición inmediata una vez autorizado</li>
    </ul>
    <p class="textoverde"><strong>Características:</strong></p>
    <ul class="contenido">
        <li>El cobro se hace con <strong>cargo automático</strong> a tu cuenta de nómina en <strong>una sola exhibición </strong>de tu siguiente pago salarial, acorde a tu <strong>esquema</strong> (semanal o quincenal)<sup>3</sup></li>
    </ul>
    <p class="textoverde"><strong>Requisitos</strong></p>
    <ul class="contenido">
        <li>Tener 18 años </li>
        <li><strong>Identificación oficial vigente</strong><sup>4</sup> (IFE/INE o Pasaporte, Tarjeta Residente Temporal/Permanente<sup>5</sup>)</li>
        <li><strong>Comprobante de domicilio, máximo 3 meses de antigüedad</strong><sup>4</sup> (Luz, Agua,Predial,Cable-Internet-Teléfono)</li>
        <li><strong>Llave maestra</strong> para tu acceso al sistema</li>
        
        
    </ul>
   
    <div class="subindices">
        <p><sup>1</sup>Sujeto a capacidad de pago</p>
        <p><sup>2</sup>No disponible para Adamantium</p>
        <p><sup>3</sup>Consulta  el calendario de pago en la guía rápida  dentro de los materiales de apoyo</p>
        <p><sup>4</sup>Tendrán vigencia de un año</p>
        <p><sup>5</sup>Identificación oficial para colaboradores extranjeros</p>
    </div>
   
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
          </div>
          <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
            <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
          </div>
    </div>
    <hr class="prodBottom">
    <p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
    <p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> 55 7099 1199 – Opción 5</p>
    <div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
        href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div><br><br><br>


    <div class="abrir-modal animacion fadeIn" *ngIf="modalVideo">
        <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div>
                    <button (click)="cerrarVideo()" type="button" class="close cmodal" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
             </button>
                </div>
                    
                <div class="embed-responsive embed-responsive-21by9">
                    <iframe title="" class="embed-responsive-item" src="../../../../../../assets/videos/VideoAdE.mp4"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>
