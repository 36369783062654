export class RequestDocumentoModel {
    idProducto: number;
    numeroEmpleado: string;
    documentos = [];
}

export class DocumentoInModel {
    idDocumento: number;
    nombre: string;
    base64: string;
    extension: string;

}