import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HeaderService } from '../header.options';
import { CotizadorReq} from '../../../models/credito/3.configurador/cotizadorReq.model';
import { catchError } from 'rxjs/operators';
import { ValidacionErrors } from '../../utileria/validacion.error';
import { DialogOverviewMsg} from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { MatDialog } from '@angular/material/dialog';
@Injectable({ providedIn: 'root' })
export class ApiAdEService {

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private headers: HeaderService,
    private validacionError: ValidacionErrors

  ) { }

  // Metodo para obtener los montos del configurador(MontoSolicitado, comision,montoAdelanto, fechaCobro)
  // Recibe parametros numeroEmpleado y monto para calcular
  getObtenerMontosConfigurador(cotizadorReq: CotizadorReq): Observable<any> {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', '' + cotizadorReq.numeroEmpleado);
    parametros.set('montoSolicitado', '' + cotizadorReq.montoSolicitado);

   return this.httpClient.get<any>(
      environment.api_url_adelanto_cotizadores + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }).pipe(
        catchError( e => {
    throw e;
        })
      );

  }

  // Metodo para guardar la solicitud con(MontoSolicitado, comision,montoAdelanto, fechaCobro)
  // Recibe parametros numeroEmpleado y monto para calcular y guardar
   postGuardarCotizacionMontosConfigurador(cotizadorReq: CotizadorReq): Observable<any> {

    return this.httpClient.post<any>(
       environment.api_url_adelanto_cotizaciones, cotizadorReq,
       { headers: this.headers.postHeaderOption(false) }).pipe(
        catchError( e => {
    throw e;
        })
      );
   }


   openDialogMsg(msg: string): void {
    this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true,
        autoFocus: false
      }
    });
  }
}
