import {Component, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  message1: string;
  message2: string;
  btn1: string;
  btn2: string
}

@Component({
  selector: 'app-dialog-validacion',
  template: `
  <div class="modalSmall">
  <p>{{data.message}}</p>
  <p>{{data.message1}}</p>
  <p>{{data.message2}}</p>
  <div class="btns_centrados">
  <button type="button" class="btn cancelar" (click)="declinar()">{{data.btn1}}</button>
  <button type="button" class="btn all" (click)="cerrarModal()" >{{data.btn2}}</button>
  </div>
  <div class="footer">&nbsp;</div>
  </div>
`,
  styleUrls: ['dialog-overview.css']
})

export class DialogValidacionComponent {

  disabledButton: boolean;
  url: string;

  @ViewChild('spanDeclinar') spanDeclinar: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<DialogValidacionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.dialogRef.disableClose = true;
    }


  cerrarModal(): void {
    this.dialogRef.close(true);
  }

  declinar(): void {
    this.dialogRef.close(false);
  }

}
