import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface DialogDataCan {
  message: string;
  opciones: boolean;
}
@Component({
  selector: 'app-dialog-recompra',
  template: ` <div class="modalSmall">
  <p>¡Ya contamos con registro de tus datos!
  ¿Deseas realizar alguna actualización?</p>
<div class="btns_centrados">
  <button type="button" class="btn cancelar" (click)="abrirDatosSocio()">Si, actualizar mis datos</button>
  <button type="submit" class="btn all" (click)="abrirConfigurador()">No, ir al cotizador</button>
</div>
<div class="footer">&nbsp;</div>
</div>`,
  styleUrls: ['dialog-overview-msg1.css']
})

export class DialogRecompraComponent {
  cancelaciones = [];
  opciones: boolean;
  datosCancelaciones: any;
  numEmpleado: string;
  producto: string;
  constructor(
    public dialogRef: MatDialogRef<DialogRecompraComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCan) {
      this.dialogRef.disableClose = true;
    }

    abrirDatosSocio(): void {
    this.dialogRef.close(false);
  }

  abrirConfigurador() {
    this.dialogRef.close(true);
  }


}
