import { Component,Host, OnInit,Input } from '@angular/core';
import { HomeComponent } from '../../home.component';


@Component({
  selector: 'app-creditobf',
  templateUrl: './creditobf.home.html',
  styleUrls: ['./creditobf.home.scss']
})
export class CreditoBFComponent implements OnInit {

  parentHome: HomeComponent;

  

  @Input() activoli;
  constructor( parentHome: HomeComponent) {
    this.parentHome = parentHome;
  }

  ngOnInit() {
  }

}
