import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CancelacionModel } from '../../../../core/models/credito/datos.socios/cancelacion.model';
import { ApiDatosSocioService } from '../../../../core/services/credito/api.datos.socio/api.datos.socio.service';
export interface DialogDataCan {
  message: string;
  opciones: boolean;
}
@Component({
  selector: 'app-dialog-overview-msg1',
  template: `
  <div class="modalSmall">
            <p>{{data.message}}</p>
        <div class="btns_centrados">
            <button type="button" class="btn cancelar" (click)="cerrarModal()">No, deseo continuar</button>
            <button type="submit" class="btn all" (click)="cancelar()">Si, regresar al inicio</button>
        </div>
    <div class="footer">&nbsp;</div>
  </div>
  `,
  styleUrls: ['dialog-overview-msg1.css']
})
export class DialogMensajeComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogMensajeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataCan) {
      this.dialogRef.disableClose = true;
    }

  cerrarModal(): void {
    this.dialogRef.close(false);
  }

  cancelar() {
    this.dialogRef.close(true);
  }
}
