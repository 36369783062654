<div class="bannerPrestamo">
    <div class="acceso">
    </div>
</div>
<div class="complementos">
    <a class="folleto" href="../../../../../../assets/docs/home/prestaPrendaFolleto.pdf" target="_blank" rel="noopener noreferrer"> Folleto</a>
    <a class="guia_rapida" href="../../../../../../assets/docs/home/prestaPrendaInfografia.pdf" target="_blank" rel="noopener noreferrer"> Guía rápida</a>
    <a class="faqs" href="../../../../../../assets/docs/home/prestaPrendaFaqs.pdf" target="_blank" rel="noopener noreferrer"> FAQ's</a>
</div>
<h1 class="titleHome">¡Conoce los beneficios de Presta Prenda!</h1>
<p class="textoverde"> <strong>¿Qué es?</strong></p>
<p> Es un producto que tiene 3 modalidades en las que puedes aprovechar sus beneficios</p>
<ul class="contenido">
    <li> Descuento en venta joyería</li>
    <li>Préstamo prendario</li>
    <li>Resguardo</li>
</ul>
<p class="textoverde"><strong>¿Para quién es?</strong></p>
<p>Todos los Colaboradores de GS</p>
<p class="textoverde"><strong>¿Qué beneficios exclusivos te ofrece?</strong></p>
<p><strong>Venta de piezas de oro</strong></p>
<p>Se aplica un descuento adicional con base a la antigüedad en todas las piezas de joyería (excepto, relojes, monedas y materia prima). </p>
<p>Con un tope de venta de $30, 000 para Colaboradores y $90,000 para directores</p>
<div  class="container" >
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-10 col-xl-8 ">
           
            <img  style ="width: 50%;" src="../../../../assets/img/tabla1PrestaPrenda.jpg" />
            
          </div>

    </div>
</div>
<p><strong>Préstamo Prendario</strong></p>
<p> Tasa preferencial y descuento en la tasa de interés de acuerdo con tu antigüedad  </p>
<p>La tasa de interés preferencial es aplicable en todos los puntos de venta y en todas las piezas de oro (excepto monedas)</p>
<p>Refrendos ilimitados y abonos a capital desde $1 peso. </p>
<p>Aplica para producto Tradicional y Tradicional 13</p>
<p>Sujeto a Evaluación de crédito</p>
<div  class="container" >
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-10 col-xl-8 ">
           
            <img  style ="width: 50%;" src="../../../../assets/img/tabla2PrestaPrenda.jpg" />
            
          </div>

    </div>
</div>

<p><strong>Resguardo de piezas:</strong></p>
<p>Tasa preferencial y descuento en la tasa de interés de acuerdo con tu antigüedad </p>
<p>La tasa de interés preferencial es aplicable en todos los puntos de venta y en todas las piezas de oro (excepto monedas)</p>
<p>Refrendos ilimitados y abonos a capital desde $1 peso. </p>
<p>Aplica para producto ECO<sup>1</sup>Y ECO13<sup>2</sup></p>
<div  class="container" >
    <div class="row">
        <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-10 col-xl-8 ">
           
            <img  style ="width: 50%;" src="../../../../assets/img/tabla3PrestaPrenda.jpg" />
            
          </div>

    </div>
</div>
<p class="textoverde"><strong>Requisitos</strong></p>
<p>Presentar tu identificación oficial vigente y gafete.</p>
<p>La prenda debe ser de 8 a 24 kilates.</p>


<p class="textoverde"><strong>¿Dónde lo contrato?</strong></p>
<p>En las tiendas Elektra que cuenten con módulo de Presta Prenda o directamente en las sucursales Presta Prenda.</p>



<br>

<div class="subindices">
    <p><sup>1</sup> Subproducto de Dinero al Instante con Banco Azteca, crédito simple con garantía prendaria que otorga Banco Azteca, S.A., Institución de Banca Múltiple el cual puedes obtener dejando como garantía tus prendas de oro.</p>
    <p><sup>2</sup> Subproducto de Dinero al Instante con Banco Azteca, crédito simple con garantía prendaria que otorga Banco Azteca, S.A., Institución de Banca Múltiple con el que tienes acceso a un crédito inmediato y plazos amplios para realizar tus pagos.</p>

</div>
<br>
<div class="row">
    <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6"  >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS1.png" />
      </div>
      <div  class="col-sm-12 col-xs-12 col-lg-12 col-md-12 col-xl-6" >
        <img  class = "imgWidth" src="../../../../assets/img/footerDGS2.png" />
      </div>
</div>
<hr class="prodBottom">
<p class="contactanos text-center">:.&nbsp;&nbsp;&nbsp;Contáctanos&nbsp;&nbsp;&nbsp;.:</p>
<p class="telefono text-center"><img src="../../../../assets/img/tel.svg" alt="teléfono" /> 55 7099 1199 <br> Opción 5</p>
<div class=" text-center"><a class="correo" target="_blank" rel="noopener noreferrer"
    href="mailto:beneficiosfinancierosgs@gruposalinas.com.mx">beneficiosfinancierosgs@gruposalinas.com.mx</a></div><br><br><br>

<div class="abrir-modal animacion fadeIn" *ngIf="modalVideo">
    <div class="modal zoom" tabindex="-1" role="dialog" style="display:block;">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <button (click)="cerrarVideo()" type="button" class="close cmodal" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe title="" class="embed-responsive-item"
                        src="../../../../../../assets/videos/VideoCrediAuto.mp4"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>

<script>
    if ( self === top ) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }  
        
    function redireccionAlSitio() {  
        try {
            var surl = self;  
            if (validateURL(surl))  
            top.location.href = surl;
            else {  
            throw new InvalidURLException();  
            }  
        } catch (e) {  
            if (e instanceof InvalidURLException)  
            alert(e.message);  
        }  
    }  
    
    function InvalidURLException() {  
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";  
        this.toString = function () {  
            return this.message  
        };  
    }  
    
    function validateURL(surl) {  
        var url = parseURL(surl);  
        var urlHostname = url.hostname.trim();  
    
        if (urlHostname == '') {  
            return true;  
        } else {  
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {  
            return true;  
            } else {
            return false;  
            }
        }  
    }  
    
    function parseURL(url) {
    var a = document.createElement('a');  
    a.href = url;  
    return {  
        source: url,  
        protocol: a.protocol.replace(':', ''),  
        hostname: a.hostname,  
        host: a.host,  
        port: a.port,  
        query: a.search,  
        params: (function () {  
            var ret = {},  
                seg = a.search.replace(/^\?/, '').split('&'),  
                len = seg.length, i = 0, s;  
            for (; i < len; i++) {  
                if (!seg[i]) { continue; }  
                s = seg[i].split('=');  
                ret[s[0]] = s[1];  
            }  
            return ret;  
        })(),  
        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],  
        hash: a.hash.replace('#', ''),  
        path: a.pathname.replace(/^([^\/])/, '/$1'),  
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],  
        segments: a.pathname.replace(/^\//, '').split('/')  
        };  
    }
</script>