/* Clase Componente del configurador de montos de ADE Y PS para seleccionar
el monto de prestamo y adelanto asi como de los plazos en el caso del prestamo
*/
import { Component, OnInit, Input } from '@angular/core';
import { UserService, ApiCreditosService } from 'src/app/core';
import { Router } from '@angular/router';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import { MatDialog } from '@angular/material/dialog';
import { Intentos, OpcionesIntentos } from 'src/app/core/services/utileria/utileria.enum';

import { DialogOverviewMsg } from '../../../../shared/components/modales/modalMaterialMsg/dialog-overview-msg';
import { DialogReintentarComponent } from '../../../../shared/components/modales/modalMaterialReintentar/dialog-overview-msg';
import { DialogCancelacionComponent } from '../../../../shared/components/modales/modalMaterialCancelaciones/dialog-overview-msg1';
import { DialogMensajeComponent } from '../../../../shared/components/modales/modalMaterialCancelaciones.1/dialog-overview-msg1';
import { CotizadorReq } from 'src/app/core/models/credito/3.configurador/cotizadorReq.model';
import { ApiAdEService } from 'src/app/core/services/credito/api.adelanto.efectivo/api.adeE.cotizaciones';
import { CotizadorAdelanto } from 'src/app/core/models/credito/3.configurador/cotizador.adelanto.model';
import { ConfiguracionModel } from 'src/app/core/models/credito/api.productos/configuracion.model';
import { CotizadorPrestamoReq } from '../../../../core/models/credito/3.configurador/cotizadorReq.prestamo';
import { CotizadorPrestamoPost } from '../../../../core/models/credito/3.configurador/cotizadorPost.model';
import { SolicitudReqModel } from '../../../../core/models/credito/api.creditos/solicitudReq.model';
import { ValidacionErrors } from '../../../../core/services/utileria/validacion.error';
import { ApiPrestamoCotizadorService } from '../../../../core/services/credito/api.prestamos/api.prestamos.cotizaciones';
enum Producto {
  ADELANTO_EFECTIVO = 'adelanto',
  PRESTAMO = 'préstamo'
}

@Component({
  selector: 'app-configurador',
  templateUrl: './configurador.component.html'
})
export class ConfiguradorComponent implements OnInit {

  // TODO: eliminar cuando se obtenga la información del empleado logueado y el producto seleccionado
  numEmpleado: string;
  intentos: number;
  maxDigitos: number;
  idProducto: number;
  idSolicitud: string;
  montoDeposito: string;
  montoTotal: string;
  comision: string;
  comisionInfo = '';
  public max: number;
  public min: number;
  value: number;
  options = {
    floor: 0,
    ceil: 0,
    showSelectionBar: true,
    showSelectionBarEnd: false,
    step: 0
  };
  iva;
  valorInput: string;
  public producto: number;
  txtProducto = '';
  tasa = 0;
  fecha = '14 / Feb / 2019';
  capacidadPago = '70000';
  plazo: any;
  idTipoNomina = '1';
  listaPlazos: any;
  recompra = '';
  constructor(private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private apiAdEService: ApiAdEService,
    private validacionError: ValidacionErrors,
    private apiCreditos: ApiCreditosService,
    private apiPrestamoCotizadorService: ApiPrestamoCotizadorService
  ) {

  }


  ngOnInit(): void {    
    this.plazo = {
      idPlazo: '7',
      plazo: '24'
    };
    this.intentos = 1;
    this.numEmpleado = window.sessionStorage ? this.userService.getNumeroEmpleado() : '';
    // Se validara el tipo de producto para con base en esto mostrar los textos que corresponden a dicho producto
    if (window.sessionStorage) {
      const idProducto = window.sessionStorage ? sessionStorage.getItem('producto_id') : '';
      this.recompra = window.sessionStorage ? sessionStorage.getItem("recompra") : 'true';
      if (idProducto !== null && idProducto !== undefined && idProducto.trim() !== '') {
        this.producto = +idProducto;
        if (this.producto != null) {
          this.txtProducto = this.producto === 1 ? Producto.ADELANTO_EFECTIVO : Producto.PRESTAMO;
          this.maxDigitos = this.producto === 1 ? 8 : 10;
        }
        // TODO:API de CDP para obtener montos iniciales
        this.generarSolicitudCredito();
      } else {
        setTimeout(() => {
          this.openDialogMsg('Por favor selecciona un producto para continuar', true);
        });
      }
    }
  }


  manejoErrorConexion(error, opcion: number) {
    if (error.status === 400 || (error.status > 402 && error.status < 500)) {
      if (opcion !== OpcionesIntentos.COTIZACIONES_POST) {
        this.dialog.closeAll();
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
      this.openDialogReintentar(this.validacionError.getError(error), 'OK', false, opcion);

    } else if (error.status === 500 && this.intentos < Intentos.MAX_INTENTOS) {
      this.intentos++;
      this.dialog.closeAll();

      this.openDialogReintentar('Ocurrio un problema de conexión. Intentalo nuevamente', 'Reintentar', true, opcion);
    } else if (error.status === 500 && this.intentos >= Intentos.MAX_INTENTOS) {
      this.dialog.closeAll();
      this.openDialogReintentar('Ocurrio un error al conectar con los servicios. Por favor inténtalo más tarde', 'OK', false, opcion);
      this.router.navigate(['/home'], { skipLocationChange: false });
      this.intentos = 1;
    }
  }

  obtenerPlazos() {
    this.apiPrestamoCotizadorService.getObtenerPlazos(this.numEmpleado).subscribe(response => {
      this.intentos = 1;
      this.listaPlazos = response.resultado.plazos;
    }, (error) => {
      this.manejoErrorConexion(error, OpcionesIntentos.COTIZACIONES_GET);
    });
  }

  actualizarplazo() {
    console.log(this.plazo);
  }

  generarSolicitudCredito() {
    const solicitud = new SolicitudReqModel();
    solicitud.numeroEmpleado = this.numEmpleado;
    solicitud.idProducto = this.producto;
    this.apiCreditos.postSolicitud(solicitud).subscribe(response => {
      this.intentos = 1;
      this.idSolicitud = response.resultado.idSolicitud;
      this.asignarCapacidadPago(response.resultado.idSolicitud);

    }, (error) => {
      this.manejoErrorConexion(error, OpcionesIntentos.GENERA_SOLICITUD);
    });
  }

  asignarCapacidadPago(idSolicitud: string) {
    this.apiCreditos.getCapacidadDePago(this.numEmpleado, idSolicitud, this.producto).subscribe(response => {
      if (response.resultado.codigo !== undefined && response.resultado.codigo === 1) {
        console.log(response);
        const configuracionMonto: ConfiguracionModel = response.resultado as ConfiguracionModel;
        this.min = configuracionMonto.montoMinimo;
        this.max = configuracionMonto.montoMaximo;
        if (sessionStorage.getItem('montoCredito')) {
          this.value = parseInt(sessionStorage.getItem('montoCredito'), 10);
          this.valorInput = '' + sessionStorage.getItem('montoCredito');
        } else {
          this.value = configuracionMonto.montoMaximo;
          this.valorInput = '' + configuracionMonto.montoMaximo;
        }
        this.options = {
          floor: this.min,
          ceil: this.max,
          showSelectionBar: true,
          showSelectionBarEnd: false,
          step: configuracionMonto.incremento
        };
        this.intentos = 1;
        this.producto === 1 ? this.obtenerMontos('' + this.value) : this.obtenerCotizacionPrestamo('' + this.value);
      } else if (response.resultado.codigo !== undefined && response.resultado.codigo === 2) {
        this.dialog.closeAll();
        this.openDialogMsg('¡Lo sentimos! Se ha rechazado tu solicitud, te recomendamos revisar' +
          ' tu situación crediticia con Buró de Credito/Círculo de Crédito', true );
      } else if (response.resultado.codigo !== undefined && response.resultado.codigo === 4) {
        this.dialog.closeAll();
        this.openDialogMsg('¡Lo sentimos! Tú capacidad de pago no es la óptima para el' +
          ' otorgamiento del crédito', true);
      }


    }, (error) => {
      this.manejoErrorConexion(error, OpcionesIntentos.CAPACIDAD_PAGO);

    });
  }

  obtenerCotizacionPrestamo(valorMonto: string) {
    valorMonto = '21000';
    const cotizador = new CotizadorPrestamoReq();
    cotizador.numeroEmpleado = this.numEmpleado;
    cotizador.montoSolicitado = valorMonto;
    cotizador.capacidadPago = this.capacidadPago;
    cotizador.plazo = this.plazo.plazo;
    cotizador.idTipoNomina = this.idTipoNomina;
    this.apiPrestamoCotizadorService.getObtenerCotizacionPrestamo(cotizador).subscribe(response => {
      this.intentos = 1;
      this.obtenerPlazos();
      this.asignarMontosPrestamo(response.resultado);
    }, (error) => {
      this.manejoErrorConexion(error, OpcionesIntentos.COTIZACIONES_GET);
    });
  }
  // Metodo para consumir API para obtener (MontoAdelanto, comision, Monto Solicitado, fechaCobro)
  obtenerMontos(valorMonto: string) {
    // TODO:API Para obtener (MontoAdelanto, comision, Monto Solicitado, fechaCobro)
    const cotizador = new CotizadorReq();
    cotizador.numeroEmpleado = this.numEmpleado;
    cotizador.montoSolicitado = valorMonto;
    this.apiAdEService.getObtenerMontosConfigurador(cotizador).subscribe(response => {
      const cotizadorAdelanto: CotizadorAdelanto = response.resultado as CotizadorAdelanto;
      this.intentos = 1;
      this.asignarMontosAdelanto(cotizadorAdelanto);
    }, (error) => {
      this.manejoErrorConexion(error, OpcionesIntentos.COTIZACIONES_GET);
    });
  }

  guardarMontos() {
    this.producto === 1 ? this.guardarCotizacionAdelanto() : this.guardarCotizacionPrestamo();
  }

  guardarCotizacionAdelanto() {
    const cotizador = new CotizadorReq();
    cotizador.numeroEmpleado = this.numEmpleado;
    cotizador.montoSolicitado = '' + this.value;;
    cotizador.idSolicitud = this.idSolicitud;
    if (this.montoDeposito !== undefined && this.montoTotal !== undefined && this.max !== undefined) {
      const valorInp = parseInt(this.valorInput, 10);
      if (valorInp > this.max || valorInp < this.min) {
      this.openDialogMsg('La cantidad capturada no esta dentro del mínimo y máximo permitido.' +
      ' Ingresa de nuevo el monto a solicitar', false);
      this.value = (valorInp > this.max) ? this.max : (valorInp < this.min) ?
      this.min : ((valorInp % 50) >= 25) ? valorInp + (50 - valorInp % 50) : valorInp - valorInp % 50;
      this.valorInput = '' + this.value;
      // TODO:API Para obtener (MontoAdelanto, comision, Monto Solicitado, fechaCobro)
      this.producto === 1 ? this.obtenerMontos('' + this.value) : this.obtenerCotizacionPrestamo('' + this.value);  
      } else {
        this.apiAdEService.postGuardarCotizacionMontosConfigurador(cotizador).subscribe(response => {
          this.intentos = 1;
          sessionStorage.setItem('montoCredito', cotizador.montoSolicitado);
          sessionStorage.setItem('idCotizador', response.resultado.idCotizador);
          this.router.navigate(['/autorizasolicitud'], { skipLocationChange: false });
        }, (error) => {
          this.manejoErrorConexion(error, OpcionesIntentos.COTIZACIONES_POST);
        });
      }


    } else {
      this.apiAdEService.openDialogMsg('Ocurrió un error al conectar con los servicios. Por favor intente más tarde.');
      this.router.navigate(['/home'], { skipLocationChange: false });

    }
  }

  guardarCotizacionPrestamo() {
    const cotizador = new CotizadorPrestamoPost();
    cotizador.numeroEmpleado = this.numEmpleado;
    //se debe regresar al valor.
    //cotizador.montoSolicitado = this.value;
    cotizador.montoSolicitado = 21000;
    cotizador.capacidadPago = +this.capacidadPago;
    cotizador.idTipoNomina = +this.idTipoNomina;
    cotizador.idPlazo = +this.plazo.idPlazo;
    cotizador.plazo = +this.plazo.plazo;
    if (this.montoDeposito !== undefined && this.max !== undefined) {
      this.apiPrestamoCotizadorService.postGuardarCotizacionPrestamo(cotizador).subscribe(response => {
        this.intentos = 1;
        sessionStorage.setItem('montoCredito', '' + cotizador.montoSolicitado);
        sessionStorage.setItem('idCotizador', response.resultado.idCotizador);
        this.router.navigate(['/autorizasolicitud'], { skipLocationChange: false });
      }, (error) => {
        this.manejoErrorConexion(error, OpcionesIntentos.COTIZACIONES_POST);
      });
    } else {
      this.apiAdEService.openDialogMsg('Ocurrió un error al conectar con los servicios. Por favor intente más tarde.');
    }
  }

  private asignarMontosAdelanto(cotizadorAdelanto: CotizadorAdelanto) {
    this.comisionInfo = '' + cotizadorAdelanto.comision;
    this.montoDeposito = '' + cotizadorAdelanto.montoSolicitado;
    const comision: number = cotizadorAdelanto.montoComision;
    this.comision = comision.toFixed(2);
    this.iva = cotizadorAdelanto.montoIvaComision;
    this.montoTotal = '' + cotizadorAdelanto.montoCobro;
  }
  private asignarMontosPrestamo(cotizadorPrestamo) {
    this.montoDeposito = '' + cotizadorPrestamo.montoSolicitado;
    this.tasa = cotizadorPrestamo.tasa;
    this.fecha = cotizadorPrestamo.siguientePago;
  }

  /* Funcion que detecta los cambios en el slider mientras se mueve para
  cambiar el valor en el input y valores de la tabla de acuerdo a la regla de negocio*/
  onUserChange(changeContext: ChangeContext): void {
    this.value = changeContext.value;
    this.valorInput = '' + changeContext.value;
  }

  onUserChangeEnd(changeContext: ChangeContext): void {
    this.value = changeContext.value;
    this.valorInput = '' + changeContext.value;
    // TODO:API Para obtener (MontoAdelanto, comision, Monto Solicitado, fechaCobro)
    this.producto === 1 ? this.obtenerMontos('' + this.value) : this.obtenerCotizacionPrestamo('' + this.value);
  }

  cambiaValor() {
    const valorInp = isNaN(parseInt(this.valorInput, 10)) ? 0 : parseInt(this.valorInput, 10);
    console.log(valorInp);
    if (valorInp > this.max || valorInp < this.min) {
      this.openDialogMsg('La cantidad capturada no esta dentro del mínimo y máximo permitido.' +
      ' Ingresa de nuevo el monto a solicitar', false);
    }
    // Ternario que determina el valor del input en caso de poner una cantidad que no esta en rango o en valor de step
    this.value = (valorInp > this.max) ? this.max : (valorInp < this.min) ?
    this.min : ((valorInp % 50) >= 25) ? valorInp + (50 - valorInp % 50) : valorInp - valorInp % 50;
    this.valorInput = '' + this.value;

    // TODO:API Para obtener (MontoAdelanto, comision, Monto Solicitado, fechaCobro)
    this.producto === 1 ? this.obtenerMontos('' + this.value) : this.obtenerCotizacionPrestamo('' + this.value);

  }



  openDialogMsg(msg: string, route: boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewMsg, {
      width: '500px',
      data: {
        message: msg,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (route) {
        this.router.navigate(['/home'], { skipLocationChange: false });
      }
    });

  }

  openDialogReintentar(msg: string, boton: string, reintento: boolean, opcion: number): void {
    const dialogRef = this.dialog.open(DialogReintentarComponent, {
      width: '500px',
      data: {
        message: msg,
        boton: boton,
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (reintento) {
        switch (opcion) {
          case 1: this.generarSolicitudCredito(); break;
          case 2: this.asignarCapacidadPago(this.idSolicitud); break;
          case 3: this.obtenerMontos('' + this.value); break;
          case 4: this.guardarMontos(); break;
          default: console.log('opcion no valida');
        }
      }
    });
  }


  routeFunction(ruta: String) {
    this.router.navigate([ruta], { skipLocationChange: false });
  }

  openDialogCancelaciones(): void {
    const dialogRef = this.dialog.open(DialogCancelacionComponent, {
      width: '500px',
      data: {
        message: '',
        opciones: true,
        disableClose: true,
        rutaDestino: '/home'
      },
      autoFocus: false
    });

  }

  openDialogCancelacionesMensaje(): void {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      data: {
        message: '¿Estás seguro que deseas salir? No se guardará el avance.',
        disableClose: true
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.openDialogCancelaciones();
      }
    });
  }


}

