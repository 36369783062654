import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HeaderService } from '../header.options';
import { ResponseReferenciasSocioModel } from './response.referencias.datos.socio';
import { RequestReferenciasModel } from 'src/app/core/models/credito/referencia.model';
import { CancelacionModel } from '../../../models/credito/datos.socios/cancelacion.model';
import { catchError } from 'rxjs/operators';
import { DatosSocioModel } from 'src/app/core/models/credito/datos.socios/datos.socio.model';
import { ResponseParentescosModel } from './response.parentescos';

@Injectable({ providedIn: 'root' })
export class ApiDatosSocioService {

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private headers: HeaderService
  ) { }

  getCancelaciones(productoId): Observable<any> {
    const parametros = new URLSearchParams();
    parametros.set('idProducto', '' + productoId);

    return this.httpClient.get<any>(
      environment.api_url_cancelaciones + '?' + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }).pipe(
        catchError(e => {
          throw e;
        })
      );

  }

  postGuardarCancelacion(cancelacion: CancelacionModel): Observable<any> {
    return this.httpClient.post<any>(
      environment.api_url_cancelaciones, cancelacion,
      { headers: this.headers.postHeaderOption(false) }).pipe(
        catchError(e => {
          throw e;
        })
      );

  }



  getDatosSocio(numeroEmpleado: string): any {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numeroEmpleado);

    return this.httpClient.get(
      environment.api_url_socios + parametros.toString(),
      { headers: this.headers.getHeaderOption(false) }
    ).pipe(
      catchError(e => {
        throw e;
      })
    );
  }

  getTipoDirecciones(): any {
    return this.httpClient.get(
      environment.api_url_socios_tipo_direcciones,
      { headers: this.headers.getHeaderOption(false) }
    ).pipe(
      catchError(e => {
        throw e;
      })
    );
  }

  getAntiguedadResidencias(): any {
    return this.httpClient.get(
      environment.api_url_socios_antiguedad_residencias,
      { headers: this.headers.getHeaderOption(false) }
    ).pipe(
      catchError(e => {
        throw e;
      })
    );
  }

  guardarDatosSocio(numEmpleado: string, responseInfoEmpleado: DatosSocioModel): any {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numEmpleado);


    return this.httpClient.post(
      environment.api_url_socios + parametros.toString(), responseInfoEmpleado,
      { headers: this.headers.postHeaderOption(false) }
    ).pipe(
      catchError(e => {
        throw e;
      })
    );
  }

  getReferenciasDatosSocioPromise(numeroEmpleado: string): Promise<{}> {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numeroEmpleado);

    const promise = new Promise((resolve, reject) => {
      this.httpClient.get<ResponseReferenciasSocioModel>(
        environment.api_url_referencias + parametros.toString(),
        { headers: this.headers.getHeaderOption(false) }
      ).toPromise()
        .then(data => {
          resolve(data.resultado);
        }, error => {
          console.log('Error es', JSON.stringify(error));
          reject(error);
        }).catch();
    });

    return promise;
  }

  postReferenciasDatosSocio(numeroEmpleado: string, referenciaModel: RequestReferenciasModel): any {

    const parametros = new URLSearchParams();
    parametros.set('numeroEmpleado', numeroEmpleado);

    const promise = new Promise((resolve, reject) => {
      this.httpClient.post(
        environment.api_url_referencias + parametros.toString(),
        referenciaModel, { headers: this.headers.postHeaderOption(false) }
      ).toPromise()
        .then(data => {
          console.log('Resultado: ' + JSON.stringify(data));
          resolve(data);
        }, error => {
          console.log('Error es', JSON.stringify(error));
          reject(error);
        }).catch();
    });

    return promise;
  }

  getParentescos(): any {
    const promise = new Promise((resolve, reject) => {
      this.httpClient.get<ResponseParentescosModel>(
        environment.api_url_socios_parentescos,
        { headers: this.headers.getHeaderOption(false) }
      ).toPromise()
        .then(data => {
          resolve(data.resultado);
        }, error => {
          console.log('Error es', error);
          reject(error);
        }).catch();
    });

    return promise;
  }


}
