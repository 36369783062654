import { Component, Host, OnInit, Input } from '@angular/core';
import { HomeComponent } from '../../home.component';

@Component({
  selector: 'app-prestamo',
  templateUrl: './prestamo.home.html',
  styleUrls: ['./prestamo.home.scss']
})
export class PrestamoHomeComponent implements OnInit {

  parentHome: HomeComponent;
  modalVideo = false;
  modalVideoCP = false;

  @Input() activoli;
  constructor( parentHome: HomeComponent) {
    this.parentHome = parentHome;

  }

  ngOnInit(): void {
  }

  mostrarVideo() {
    this.modalVideo = true;
  }

  mostrarVideoCP() {
    this.modalVideoCP = true;
  }

  cerrarVideo(tipo :Number) {
    switch(tipo) {
      case 1: {
        this.modalVideo = false;
      }
      case 2: {
        this.modalVideoCP = false;
      }
    }
  }

  validacionCP() {
    this.parentHome.validacionAvisoPrivacidad(2);
  }
}
