import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'FechaPipe'})
export class FechaPipe implements PipeTransform {
    transform(fecha: string): string {
        let dia = fecha.substr(0, 2);
        const mes = fecha.substr(3, 2);
        const anio = fecha.substr(-4, 4);
        let mesString: string;
        switch (mes) {
            case '01': { mesString = 'Ene'; break; }
            case '02': { mesString = 'Feb'; break; }
            case '03': { mesString = 'Mar'; break; }
            case '04': { mesString = 'Abr'; break; }
            case '05': { mesString = 'May'; break; }
            case '06': { mesString = 'Jun'; break; }
            case '07': { mesString = 'Jul'; break; }
            case '08': { mesString = 'Ago'; break; }
            case '09': { mesString = 'Sep'; break; }
            case '10': { mesString = 'Oct'; break; }
            case '11': { mesString = 'Nov'; break; }
            case '12': { mesString = 'Dic'; break; }
        }
        if (dia.substr(0, 1) === '0') {
            dia = dia.substr(1, 2);
        }
        return dia + ' / ' + mesString + ' / ' + anio;
    }
}

