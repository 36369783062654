import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../models/user.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenApiService {

  private _token: string;
  private currentUserSubject = new BehaviorSubject<UserModel>({} as UserModel);
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  private storageSub = new Subject<boolean>();

  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(private http: HttpClient) {}

   validarToken() {
    // const now = new Date().getTime() / 1000;
    // const fechaToken = parseInt(sessionStorage.getItem('fechaExp'), 10);
    if (sessionStorage.getItem('jwtToken')) {
      // if (now < fechaToken || isNaN(fechaToken) ) {
        return true;
      }
      return false;
    }
    // return true;


  // }

   guardarFecha(token: any) {
    let expira = parseInt(token.expires_in, 10);
    const now: number = new Date().getTime() / 1000;
    expira += now;
    sessionStorage.setItem('fechaExp', '' + expira.toFixed(0));
  }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  public get token(): string {
    if (this._token != null) {
      return this._token;
    } else if (
      this._token == null &&
      sessionStorage.getItem('tokenAPI') != null
    ) {
      this._token = sessionStorage.getItem('tokenAPI');
      return this._token;
    }
    return null;
  }
  guardarToken(accessToken: string): void {
    this._token = accessToken;
    sessionStorage.setItem('tokenAPI', accessToken);
    this.storageSub.next(true);
  }

  destruirToken(): void {
    this._token = null;
    sessionStorage.clear();
    sessionStorage.removeItem('token');
    this.storageSub.next(false);
  }

  guardarTokenOnpremise(accessToken: string): void {
    this._token = accessToken;
    sessionStorage.setItem('tokenApiOnpremise', accessToken);
    this.storageSub.next(true);
  }
}
