import { UtileriaEnum } from './utileria.enum';
import { Injectable } from "@angular/core";

@Injectable()
export class ValidacionErrors {

  constructor() {
  }


  getError(errors): string {
    try {
      if (errors != null && errors.error.detalles !== null &&
        errors.error.detalles !== undefined && errors.error.detalles.length > 0) {
        let mensaje = '';
        errors.error.detalles.forEach(element => {
          if(element.mensaje === undefined){
            if(errors.status === 404){
              mensaje = mensaje + "No se cuenta con información del código postal";
            }else{
              mensaje = mensaje + element;
            }
            
          }else{
            mensaje = mensaje + element.mensaje
          }
        });
        return mensaje;
      } else if (errors != null && errors.error.mensaje !== undefined) {

        const mensaje = errors.error.mensaje;
        return mensaje;
      } else {
        return UtileriaEnum.ERROR_FATAL;
      }
    } catch (err) {
      return UtileriaEnum.ERROR_FATAL;
    }
  }

  getErrorApi(errors): string {
    try {
      if (errors != null && errors.mensaje !== null &&
        errors.mensaje !== undefined && errors.mensaje !== '') {
        const mensaje = errors.mensaje;
        return mensaje;
      } else {
        return UtileriaEnum.ERROR_FATAL;
      }
    } catch (err) {
      return UtileriaEnum.ERROR_FATAL;
    }
  }

}

