<form formDS id="formDS" class="content_form" [formGroup]="datosSocio" (ngSubmit)="continuar(datosSocio.valid)"
    autocomplete="off">
    <h2 *ngIf="showLabels" class="title">1. Datos del Empleado.</h2>
    <p>Verifica tus datos, puedes editar los campos si requieres actualizarlos.</p>
    <br>
    <h3 class="subtitle">Personales:</h3><br>
    <div class="row">
        <div class="col">
            <mat-form-field class="example-full-width">
                <input type="text" name="telefono" [formControl]="datosSocio.controls.telefono" 
                    placeholder="Teléfono Celular (10 dígitos)" matInput maxlength="14" mask="00 00 00 00 00"
                    minlength="6">
                <mat-error
                    *ngIf="datosSocio.controls.telefono.errors && !datosSocio.controls.telefono.errors?.telConsecutivo">
                    Verifica el número de teléfono
                </mat-error>
                <mat-error *ngIf="datosSocio.controls.telefono.errors?.telConsecutivo">
                    Verifica que el número de teléfono sea personal.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col">
            <mat-form-field class="example-full-width">
                <input type="text" name="correo" [formControl]="datosSocio.controls.correo" 
                    placeholder="Correo personal" matInput (blur)="verificarEmail()">
                <mat-error *ngIf="datosSocio.controls.correo.errors
        && !datosSocio.controls.correo.errors?.prohibidos">
                    Verifica el correo electrónico
                </mat-error>
                <mat-error *ngIf="!!datosSocio.controls.correo.errors?.prohibidos">
                    Verifica que el correo electrónico sea personal.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <br>
    <h3 class="subtitle">Dirección:</h3><br>
    <p>Tu domicilio actual debe coincidir con tu comprobante de domiclio.</p>
    <div class="row">
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="calle" [formControl]="datosSocio.controls.calle" placeholder="Calle"
                    maxlength="50" (change)="actualizarDomicilio()" matInput>
                <mat-error
                    *ngIf="!datosSocio.controls.calle.errors?.minlength && !datosSocio.controls.calle.errors?.required">
                    Verifica la calle
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col col-3">
            <mat-form-field class="example-full-width">
                <input type="text" name="numero_exterior" [formControl]="datosSocio.controls.numero_exterior"
                    placeholder="Número ext." maxlength="10" (change)="actualizarDomicilio()" matInput>
                <mat-error
                    *ngIf="!datosSocio.controls.numero_exterior.errors?.minlength && !datosSocio.controls.numero_exterior.errors?.required">
                    Verifica el Número ext.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col col-3">
            <mat-form-field class="example-full-width">
                <input type="text" [formControl]="datosSocio.controls.numero_interior" placeholder="Número int."
                    maxlength="10" matInput>
                <mat-error
                    *ngIf="!datosSocio.controls.numero_interior.errors?.minlength && !datosSocio.controls.numero_exterior.errors?.required">
                    Verifica el Número int.
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <p [ngClass]="{'error-socios': colorCalle}">Ingresa al menos 2 calles de referencia de tu domicilio.</p>

    <div class="row">
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="calle_adelante" [formControl]="datosSocio.controls.calle_adelante"
                    maxlength="50" placeholder="Calle adelante" matInput (keyup)="validarCalles1()" (blur)="validarCalles1()">
                <mat-error
                    *ngIf="!datosSocio.controls.calle_adelante.errors?.minlength && !datosSocio.controls.calle_adelante.errors?.required">
                    Verifica la Calle adelante
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="calle_atras" [formControl]="datosSocio.controls.calle_atras"
                    placeholder="Calle atŕas" maxlength="50" matInput (keyup)="validarCalles1()" (blur)="validarCalles1()">
                <mat-error
                    *ngIf="!datosSocio.controls.calle_atras.errors?.minlength && !datosSocio.controls.calle_atras.errors?.required">
                    Verifica la Calle átras
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="calle_derecha" [formControl]="datosSocio.controls.calle_derecha" maxlength="50"
                    placeholder="Calle derecha" matInput (keyup)="validarCalles1()" (blur)="validarCalles1()">
                <mat-error
                    *ngIf="!datosSocio.controls.calle_derecha.errors?.minlength && !datosSocio.controls.calle_derecha.errors?.required">
                    Verifica la Calle derecha
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="calle_izquierda" [formControl]="datosSocio.controls.calle_izquierda"
                    maxlength="50" placeholder="Calle izquierda" matInput (keyup)="validarCalles1()" (blur)="validarCalles1()">
                <mat-error
                    *ngIf="!datosSocio.controls.calle_izquierda.errors?.minlength && !datosSocio.controls.calle_izquierda.errors?.required">
                    Verifica la Calle izquierda
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="codigo_postal" [formControl]="datosSocio.controls.codigo_postal"
                    (change)="obternerDatosPorCP()" placeholder="Código postal" matInput numbersOnly minlength="5"
                    maxlength="5">
                <mat-error>
                    Código postal incorrecto.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col col-6">
            <mat-form-field>
                <mat-select [formControl]="datosSocio.controls.colonia" [value]="colonia" placeholder="Colonia"
                    (selectionChange)="actualizarDomicilio()">
                    <mat-option *ngFor="let item of listaColonias;" [value]="item.nombre">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="alcaldia_municipio" [formControl]="datosSocio.controls.alcaldia_municipio"
                    placeholder="Alcaldía / Municipio" matInput maxlength="50">
                <mat-error
                    *ngIf="!datosSocio.controls.alcaldia_municipio.errors?.minlength && !datosSocio.controls.alcaldia_municipio.errors?.required">
                    Verifica la Alcandía / Municipio
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col col-6">
            <mat-form-field class="example-full-width">
                <input type="text" name="estado" [formControl]="datosSocio.controls.estado" placeholder="Estado"
                    matInput maxlength="50">
                <mat-error
                    *ngIf="!datosSocio.controls.estado.errors?.minlength && !datosSocio.controls.estado.errors?.required">
                    Verifica el Estado
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col col-6">
            <mat-form-field>
                <mat-select [formControl]="datosSocio.controls.tipoDomicilioClienteDesc"
                    [value]="datosSocio.tipoDomicilioClienteDesc" placeholder="El domicilio donde vive es"
                    #tipoDomicilio (valueChange)="cambiarValorTipoDomicilio(tipoDomicilio.value)">
                    <mat-option *ngFor="let item of tipoDomicilioCliente;" [value]="item.tipoDireccion" required>
                        {{item.tipoDireccion}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col col-6">
            <mat-form-field>
                <mat-select [formControl]="datosSocio.controls.antiguedadResidenciaDesc"
                    [value]="datosSocio.antiguedadResidenciaDesc" placeholder="Tiempo viviendo ahí" #antiguedad
                    (valueChange)="cambiarValorAntiguedadResidencias(antiguedad.value)">
                    <mat-option *ngFor="let item of antiguedadResidencia;" [value]="item.antiguedadResidencia" required>
                        {{item.antiguedadResidencia}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <mat-form-field class="example-full-width">
                <input type="text" name="referencia_domicilio" [formControl]="datosSocio.controls.referencia_domicilio"
                    maxlength="200" placeholder="Referencias del domicilio" matInput>
                <mat-error
                    *ngIf="!datosSocio.controls.referencia_domicilio.errors?.minlength && !datosSocio.controls.referencia_domicilio.errors?.required">
                    Verifica las Referencias del domicilio
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <br>
    <h3 class="subtitle">Ubica tu domicilio:</h3><br>
    <p>Verifica que la ubicación del puntero coincida con tu domicilio, si no es así lo puedes corregir.</p>
    <br>
    <div class="content_map" >
        <app-mapa-ggl [direccionMapa]="direccionMapa"></app-mapa-ggl>
        <div *ngIf="mostraBtnRecargar" class="cargar">
            <button *ngIf="mostraBtnRecargar" type="button" class="atrasps" (click)="recargarMapa()">Error en la carga
                del mapa<br>Presiona para recargar</button>
        </div>
    </div>
    <div *ngIf="showLabels" #btnDS id="btnDS" class="col pad btns_foot">
        <button type="button" class="btn cancelar" (click)="openDialogCancelacionesMensaje()" tabindex="6">Cancelar</button>
        <button type="submit" class="btn all" tabindex="1">Continuar</button>
    </div>
    <div *ngIf="showEditar && !showLabels" #btnPS id="btnPS" id="btnPS" class="col pad btns_foot">
        <button type="button" class="btn atrasps" (click)="routeFunction('/home')" tabindex="7"
            routerLinkActive="router-link-active">Atrás</button>
        <button type="button" class="btn all" (click)="editar()" name="btnEditar">Editar</button>
    </div>
    <div *ngIf="!showEditar && !showLabels" #btnGuardar id="btnGuardar" class="col pad btns_foot">
        <button type="button" class="btn cancelar" (click)="cancelar()">Cancelar</button>
        <button type="button" class="btn all" (click)="saveData(datosSocio.valid)">Guardar</button>
    </div>
</form>

<script>
    if (self === top) {
        document.documentElement.style.display = 'block';
    } else {
        redireccionAlSitio();
    }

    function redireccionAlSitio() {
        try {
            var surl = self;
            if (validateURL(surl))
                top.location.href = surl;
            else {
                throw new InvalidURLException();
            }
        } catch (e) {
            if (e instanceof InvalidURLException)
                alert(e.message);
        }
    }

    function InvalidURLException() {
        this.message = "Se intentó abrir una página web de dominio extranjero. No permitido.";
        this.toString = function () {
            return this.message
        };
    }

    function validateURL(surl) {
        var url = parseURL(surl);
        var urlHostname = url.hostname.trim();

        if (urlHostname == '') {
            return true;
        } else {
            if (urlHostname.toUpperCase() == location.hostname.trim().toUpperCase()) {
                return true;
            } else {
                return false;
            }
        }
    }

    function parseURL(url) {
        var a = document.createElement('a');
        a.href = url;
        return {
            source: url,
            protocol: a.protocol.replace(':', ''),
            hostname: a.hostname,
            host: a.host,
            port: a.port,
            query: a.search,
            params: (function () {
                var ret = {},
                    seg = a.search.replace(/^\?/, '').split('&'),
                    len = seg.length, i = 0, s;
                for (; i < len; i++) {
                    if (!seg[i]) { continue; }
                    s = seg[i].split('=');
                    ret[s[0]] = s[1];
                }
                return ret;
            })(),
            file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ''])[1],
            hash: a.hash.replace('#', ''),
            path: a.pathname.replace(/^([^\/])/, '/$1'),
            relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ''])[1],
            segments: a.pathname.replace(/^\//, '').split('/')
        };
    }
</script>