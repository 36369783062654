import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatosSocioComponent } from './datos.socio.component';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: 'datossocio',
    component: DatosSocioComponent,
    canActivate: [AuthGuardService]  // <-- Implementar para manejar el acceso
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DatosSocioRoutingModule {}
