import { ValidatorFn, AbstractControl } from '@angular/forms';
import { Injectable } from "@angular/core";
// Tool for patterns: https://www.debuggex.com/r/ah_JuglZGAIK_gqO#cheatsheet

@Injectable()
export class ValidacionUtils {

    patternTelConsec: string | RegExp;

    patternCorreo: RegExp;
    patternCorreoSalinas: RegExp;
    patternCorreosProhibidos: RegExp;
    patternTelGrupo: RegExp;
    patternNumRep: string | RegExp;
    patternCadenaValidaSN: string | RegExp;
    patternCadenaValida: string | RegExp;
    patternCadenaValidaColonias: string | RegExp;
    patternNombre: string | RegExp;
    patternApellidos: string | RegExp;
    patternCalles: string | RegExp;
    patternReferencia: string | RegExp;
    patternCadenaValidaNum: string | RegExp;
    constructor() {
        this.patternCorreo = /(^[a-zA-Z.-\d])+([._-]?[a-zA-Z._-\d]+)+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        this.patternCorreoSalinas = new RegExp(/^(?!.*rsalinas@|ricardosalinas@|rbs@)/, 'i');
        // tslint:disable-next-line: max-line-length
        this.patternCorreosProhibidos = new RegExp(/^(?!.*@elektra.com.mx|.*@adamantium.mx|.*@circulodecredito.com.mx|.*@circulodeproveedores.com.mx|.*@historialcertificado.com.mx|.*@acertumbank.com|.*@acertumfinancial.com|.*@aforeazteca.com.mx|.*@agenciai.mx|.*@asmaz.com.mx|.*@asmaz.org.mx|.*@audazconsultores.com|.*@autoselektra.com|.*@aztecainteractive.com|.*@aztecamovil.com|.*@aztecaservicios.com|.*@bancaempresarialazteca.com.mx|.*@bancaempresarialazteca.com.sv|.*@bancoazteca.com|.*@bancoazteca.com.gt|.*@bancoazteca.com.hn|.*@bancoazteca.com.mx|.*@bancoazteca.com.pa|.*@bancoazteca.com.pe|.*@bancoazteca.com.sv|.*@bazdigital.com|.*@bazdigital.com.mx|.*@blue-light.com.mx|.*@cncss.com.mx|.*@credimax.com.mx|.*@cuentaselecta.mx|.*@dapesoluciones.com|.*@dineroexpress.com.mx|.*@elektra.com.gt|.*@elektra.com.mx|.*@elektradinero.com.mx|.*@empresarioazteca.com.mx|.*@fazgua.org|.*@fazpe.org|.*@fiar.com.mx|.*@fulfilment.com.mx|.*@fundacionazteca.com.gt|.*@ilatina.com.mx|.*@infomovil.com|.*@interno.elektra.com.mx|.*@italika.com.gt|.*@italika.com.mx|.*@iupicard.com|.*@legisladoresproplata.org|.*@metronhomo.com|.*@metronomo.com.mx|.*@mibancoazteca.com.mx|.*@micronegocioazteca.com.mx|.*@motovox.com.mx|.*@ms-nacional.com|.*@nss.mx|.*@planlealtadazteca.com.mx|.*@plata.com.mx|.*@pocdc.com.mx|.*@prepagoekt.com.mx|.*@prestaprenda.com.mx|.*@promoespacio.com.mx|.*@segurosazteca.com.mx|.*@sophiemlm.com|.*@sphie.com.mx|.*@tiendasneto.com|.*@typhoon-offshore.com.mx|.*@typhoon.com.mx|.*@upaxer.com)/, 'i');
        this.patternTelConsec = '^(?!.*(?:01234|12345|23456|34567|45678|56789)).*$';
        this.patternNumRep = '(?!.*([0-9])\\1\\1\\1\\1\\1\\1).*';
        this.patternCadenaValida = '^(?!null)([0-9a-zA-Z- ñÑáéíóúÁÉÍÓÚ. , ; : \\\\s])*';
        this.patternCadenaValidaColonias = '^(?!null)([0-9a-zA-Z- ñÑáéíóúÁÉÍÓÚ(). , ; : \\\\s])*';
        this.patternCadenaValidaSN = '^(?!null)([0-9a-zA-Z- ñÑáéíóúÁÉÍÓÚ. , ; : / \\\\s])*';
        this.patternCadenaValidaNum = '^(?!null)([0-9a-zA-Z- \\\\s])*';
        // tslint:disable-next-line: max-line-length
        this.patternTelGrupo = /^(5517207\d\d\d|55562422\d\d|55858287\d\d|55858249\d\d|55858249\d\d|55592766\d\d|55595549\d\d|55544723\d\d|55172008\d\d|55172013\d\d|55172014\d\d|55172057\d\d|55172091\d\d|55172092\d\d|55172049\d\d|55309950\d\d|55562450\d\d|55516623\d\d|5585825\d\d\d|55858289\d\d|55757487\d\d|55623588\d\d|556377480[8-9]|556377481\d|552601536\d|5526015[4-7]\d\d|55260158[0-5]\d)$/;
        this.patternNombre = /^([a-zA-ZñÑáéíóúÁÉÍÓÚ]{1,}[.\sa-zA-ZñÑáéíóúÁÉÍÓÚ]*)$/;
        this.patternApellidos = /^([a-zA-ZñÑáéíóúÁÉÍÓÚ]{1,}[.\sa-zA-ZñÑáéíóúÁÉÍÓÚ]*)$/;
        this.patternCalles = /^([a-z0-9A-ZñÑáéíÜüóúÁÉÍÓÚ]+[.\sa-z0-9A-ZñÑáéíÜüóúÁÉÍÓÚ]*)$/;
        this.patternReferencia = /^([a-z0-9A-ZñÑáéíóúÁÉÍÓÚ]+[,-.\sa-z0-9A-ZñÑáéíóúÁÉÍÓÚ]*)$/;

    }

    // Validadores personalizados
    validatorTelefono(telExp: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const telefono = telExp.test(control.value);
            return telefono ? { 'telConsecutivo': { value: control.value } } : null;
        };
    }

    validadorCorreo(correoSalinas: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const correo = correoSalinas.test(control.value);
            return !correo ? { 'correoSalinas': { value: control.value } } : null;
        };
    }

    validadorCorreoProhibidos(patternCorreosProhibidos: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const correo = patternCorreosProhibidos.test(control.value);
            return !correo ? { 'prohibidos': { value: control.value } } : null;
        };
    }
}

